module.exports = class {
    onCreate(input) {
        this.state = {
            pressed: [],
            showDrawer: input.showDrawer
        };
    }

    handleChange(e) {
      this.emit('installer-click', this.input.model[e.index]);
    }

    onUpdate() {
        this.scrollIntoView();
    }

    onMount() {
        this.scrollIntoView();
    }

    scrollIntoView() {
        const selectedInstaller = this.input.selectedInstaller;

        if(selectedInstaller && selectedInstaller.hasOwnProperty('paramValue')) {
            const installerCard = this.getComponent(`installer-card-${selectedInstaller.paramValue}`);
            const selectedEl = installerCard && installerCard.getEl();

            if (selectedEl && selectedEl.scrollIntoView) {
                setTimeout(
                    () => {
                        selectedEl.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'start' });
                        if(this.input.variant === "no-radio") {
                            const toggleButton = selectedEl.closest(".toggle-button");
                            if(toggleButton) {
                                toggleButton.focus();
                            }
                        }
                    }, 
                    500
                );
            }
        }
    }

    handleButton({ pressed }) {
        this.state.pressed = pressed;
        this.handleChange({ index: pressed[0] })
    }
    clearSelection() {
        this.state.pressed = [];
    }
};
