$ {
   const model = input.model || {};
   const {
      model: {
         VAS_STATUS_MESSAGE: defaultError,
         VAS_HEADER_MODULE: {
            title: headerTitle,
            closeIcon,
            backIcon
         } = {},         
      } = {},
      global: {
         language,
         lang,
         country,
         isMobile
      } = {},
      mapConfig = {},
      hideBackIcon
   } = input;

   const {
      installerList = [],
      selectedInstaller = {},
      radius = 0,
      postalCode = '',
      radiusField,
      postalField,
      radiusCta,
      silhouettePartialScreen,
      silhouetteWholeScreen,
      showError,
      installerError,
      sortV2,
      mapCenterIcon,
      actions
   } = state;
};

<header 
   class-name="parts-header"
   model=closeIcon 
   on-close('emit', 'close')>
      <@headerLeft>
         <if(backIcon && !hideBackIcon)>
            <ebay-icon-button on-click('emit', 'back', 'installer', backIcon)>
                  <ebay-chevron-left-24-icon />
            </ebay-icon-button>
         </if>
      </@headerLeft>
      <@title>
         <if(headerTitle)>
            <h2 class="header-title">
                  <ux-textualdisplay model=headerTitle />
            </h2>
         </if>
      </@title>
</header>

<if(showError)>
    <error-screen model=defaultError />
</if>
<else-if(silhouetteWholeScreen)>
   <div class="parts-installer-loading">
      <screen-loading top={count:0, type:'textbox'} left={count:isMobile? 7 : 3, type:'image'} right={count:isMobile? 0 : 3, type:'image'} footer={count:isMobile? 0 : 1, type:'textbox'}/>
   </div>
</else-if>
<else>
   <div class="parts-installer">
      <div class="parts-installer__form-fields">
         <div class="parts-installer__finder">
            <find-area
               postal-code-value=postalCode
               radius-value=radius
               model={
                  radius: radiusField,
                  postalCode: postalField,
                  getInstallerList: {
                     action: radiusCta,
                  }
               }
               on-value-change('handleRadiusChange')
               on-get-installers('getInstallers')
            />
         </div>
         <div class="parts-installer__sort">
            <sort model=sortV2 key='sort' variant='drop-down' on-process-sort('handleSortResults') />
         </div>
      </div>
      <if(silhouettePartialScreen)>
         <screen-loading top={count:0, type:'textbox'} left={count:isMobile? 7 : 6, type:'composite'} right={count:isMobile? 0 : 1, type:'image'} footer={count:0, type:'textbox'}/>
      </if>
      <else> 
         <div class="parts-installer__lists">
            <div class="parts-installer__card-lists">
               <if(installerError)>
                  <notice type="inline" model=installerError />
               </if>
               <else>
                  <installer-list
                     key="installer-list"
                     language=language
                     model=installerList
                     variant="no-radio"
                     selected-installer=selectedInstaller
                     on-installer-click('installerClick', actions, false)
                  />
               </else>
            </div>
            <div class="parts-installer__map-lists">
               <if(mapConfig.enabled)>
                  <map
                     apiKey=mapConfig.key
                     center=mapCenterIcon
                     radius=radius
                     country=country
                     language=language
                     model=installerList
                     variant="arrow-tooltip"
                     show-info="info-label"
                     selected-installer=selectedInstaller
                     on-info-card-click('installerClick', actions, true)
                     on-marker-click('markerClick')
                  />         
               </if>
            </div>
         </div>
      </else>
   </div>
</else>
