$ const { has, get } = require("@ebay/retriever");

$ {
    const global = get(input, "options.global", {});
    const model = input.model || {};
    const language = global.language || global.lang || 'en';
    const country = global.country || 'US';
    const mapConfig = input.mapConfig || {};
    
    const {
        postalCode,
        radius,
        showLoading,
        showNotice,
        installerList,
        statusMessage,
        selectedInstaller,
        selectedVehicle,
        mapCenterIcon,
        installerScreenCTA,
        appointmentModel,
        loadingScreen,
        sortV2,
        vehicleModules,
        isIntro,
        isSpoke,
        layerModules,
        layerRadiusSearch,
        layerVasMeta
    } = state;

    const {
        itemCard,
        whatsIncluded,
        getInstallerList,
        howItWorks,
        vasForm,
        uxComponentName,
    } = layerModules;

    let {
        title,
        closeIcon,
    } = layerModules;

    const isSpokeLayer = isSpoke || uxComponentName && uxComponentName === "VAS_SPOKE_V2";
    let ctasToShow = installerScreenCTA;
    let disablePrimaryButton = !selectedInstaller;

// Dynamic Class names

    const redesignClassName = get(layerVasMeta, "schemaVersion.INSTALLATION", "") === "INSTALLATION_V2" ? " vas-tire-installation__installation_v2" : "" ;
    const screenClassName = get(installerScreenCTA, "sections[0].dataItems[0].action.name", "") === "VASMP_APPOINTMENT" ? " vas-tire-installation__appointment" : "";

// appointment screen
    const isAppointmentScreen = appointmentModel != null && !appointmentModel.isEmpty;

    if(isAppointmentScreen) {
        title = get(appointmentModel, "VAS_TITLE.title", {});
        closeIcon = get(appointmentModel, "VAS_CLOSE_ICON", {});
        ctasToShow = get(appointmentModel, "VAS_CTA", {});

        let appointmentInvalid = !(state.appointmentSelectedDate  && state.appointmentSelectedDate.length > 0 && state.appointmentSelectedSlot)
        disablePrimaryButton = appointmentInvalid;
    }

// vehicle screen    
    const isVehicleScreen = vehicleModules != null && !vehicleModules.isEmpty;

    if(isVehicleScreen) {
        title = get(vehicleModules, "VAS_TITLE.title", {});
        closeIcon = get(vehicleModules, "VAS_CLOSE_ICON", {});
        ctasToShow = get(vehicleModules, "VAS_CTA", {});
        disablePrimaryButton = !selectedVehicle;
    }

    const showLayer = !layerModules.isEmpty || isAppointmentScreen || isVehicleScreen;
}
<div 
        id=(isSpokeLayer ? "vas-spoke-container" : "vas-interstitial-container")
        class=`vas-tire-installation${redesignClassName}${screenClassName}`>
        <if(isIntro)>
            <intro 
                        global=global 
                        model=model
                        show-error=false
                        show-loading=showLoading
                        on-click('handleNextAction') 
                        on-close('handleClose')
                    />
        </if>
        <else-if(showLayer)>
            <!-- Header Section Common for Installer Screen and Appointment Screen-->
            <header model=closeIcon on-close("dialogClose", isSpokeLayer)>
                <@title>
                    <h2 class="header-title">
                        <ux-textualdisplay model=title />
                    </h2>
                </@title>
            </header>

            <!-- Content Section -->
            <div class="vas-tire-installation__content">

                <!--- Left Content Section -->
                <div class="vas-tire-installation__left">
                    <if(loadingScreen)>
                        <loading-indicator count=6/>
                    </if>
                    <else-if(isAppointmentScreen)>
                        <appointment model=appointmentModel  on-vas-appointment-change("handleAppointmentChange")
                            on-vas-personal-data-change("handleAppointmentLicenseChange")/>
                    </else-if>
                    <else-if(isVehicleScreen)>
                        <vehicle-finder model=vehicleModules on-ti-vehicle-finder-selection('handleVehicleChange')/>
                    </else-if>
                    <else>
                        <item-card model=itemCard />
                        <whats-included model=whatsIncluded />
                        <div class="vas-tire-installation__installer-form">
                            <if(!has(layerRadiusSearch,'isEmpty'))>
                                <find-installer model=layerRadiusSearch 
                                on-value-change("handleValueChange")
                                on-get-installers("getInstallers")
                                />
                            </if>
                            <else>
                                <find-area
                                    postal-code-value=postalCode
                                    radius-value=radius
                                    model={
                                        radius: layerModules.radius,
                                        postalCode: layerModules.postalCode,
                                        getInstallerList
                                    }
                                    on-value-change("handleValueChange")
                                    on-get-installers("getInstallers")
                                />
                            </else>
                        </div>
                        <if(sortV2)>
                            <sort model=sortV2 key="sort" is-spoke=isSpokeLayer on-process-sort('handleSort') />
                        </if>
                        <if(showLoading)>
                            <loading-indicator />
                        </if>
                        <else-if(showNotice)>
                            $ const message = get(statusMessage, 'message', {});

                            <ebay-section-notice
                                a11y-text=get(message, "additionalText.0.accessibilityText")
                                status=(message.messageType === "INFO" ? "information" : "attention")>
                                    <ux-textualdisplay model=get(message, "additionalText.0", {}) />
                                    <!-- Debug :- SVG not loading through the ebay UI-->
                                    <div hidden aria-hidden="true">
                                        <svg>
                                            <symbol viewBox="0 0 16 16" id="icon-attention-filled-16">
                                                <path d="M8 0a8 8 0 100 16A8 8 0 008 0zm0 12a1 1 0 110-2 1 1 0 010 2zm1-4a1 1 0 11-2 0V5a1 1 0 112 0v3z"></path>
                                            </symbol>
                                            <symbol viewBox="0 0 16 16" id="icon-information-filled-16">
                                                <path d="M8 0a8 8 0 100 16A8 8 0 008 0zm1 11a1 1 0 01-2 0V8a1 1 0 112 0v3zM8 6a1 1 0 110-2 1 1 0 010 2z"></path>
                                            </symbol>
                                        </svg>
                                    </div>
                            </ebay-section-notice>
                        </else-if>
                        <else-if(!showLoading )>
                            <installer-list
                            key="installer-list"
                            language=language
                            model=installerList
                            selected-installer=selectedInstaller
                            on-installer-click("handleInstallerClick") />
                        </else-if>
                    </else>
                </div>

                <!--- Right Content Section -->
                <div class="vas-tire-installation__right">
                    <!-- Map Section -->
                    <if(mapConfig.enabled)>
                        <map
                            apiKey=mapConfig.key
                            center=mapCenterIcon
                            radius=radius
                            country=country
                            language=language
                            model=installerList
                            selected-installer=selectedInstaller
                            on-installer-click("handleInstallerClick")
                            showSelectedMarkerOnly=isAppointmentScreen 
                        />
                    </if>
                    <!-- How It Works Section -->
                    <how-works model=howItWorks on-learn-more-click("tracking")/>
                </div>
            </div>
            <!--- Footer Section --->
            <div class="vas-tire-installation__footer">
            </div>
            <if(ctasToShow && !ctasToShow.__isEmpty)>
                <ctas
                    model=ctasToShow
                    disable-primary=disablePrimaryButton
                    addon-id=(selectedInstaller && selectedInstaller.paramValue)
                    isBusy=state.isBusy
                    processingActionName=state.processingActionName
                    on-click("buttonClick") />
            </if>
        </else-if>
</div>
