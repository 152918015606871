$ const {
    model: {
        title,
        image
    } = {}
} = input;

<div class="vas-itm-card">
    <div class="vas-itm-card__image">
        <if(image && image.URL)>
            <img src=image.URL alt=""/>
        </if>
    </div>
    <if(title)>
        <div class="vas-itm-card__right-content">
            <div class="vas-itm-card__title">
                <ux-textualdisplay model=title />
            </div>
        </div>
    </if>
</div>