$ const { get } = require('@ebay/retriever');
$ const { 
    model: { 
        label = {}, 
        options = [],
        entries = []
    } = {} 
} = input;

$ const { error, value } = state;
$ const radiusLabel = get(label, 'textSpans.0.text', '');

<div class='radius'>
  <div class='radius__radius-field'>
    <ebay-select
      name='radius'
      floating-label=radiusLabel
      aria-invalid=error && 'true'
      on-change('handleValueEvent', 'value-change')
    >
      <@option value=null text=radiusLabel/>
    <if(entries.length > 0)>
        <for|option| of=entries>
           <@option
                selected=(value == option.paramValue)
                value=option.paramValue
                text=get(option, 'label.textSpans[0].text', '') />
        </for>
    </if>
    <else>
        <for|option| of=options>
            <@option selected=value == option.value value=option.value text=option.text/>
        </for>
    </else>
    </ebay-select>
  </div>
</div>
