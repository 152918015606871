// Provides helper function to process VAS_RADIUS_SEARCH
// @usage extractPostCodeRadiusValues(radiusSearchForm)
const { get } = require('@ebay/retriever');

const getCommonVasSpAnc = (rootEl) => {
    let link = document.getElementById('vas-sp-nav');
    if (!link) {
      link = document.createElement('a');
      link.id = 'vas-sp-nav';
      link.setAttribute('style', 'display:none');
      if (rootEl) {
          rootEl.append(link);
      } else {
          document.body.append(link);
      }
    }
    return link;
}

const removeUrlSid = (url) => {
    if (!url) {
        return '';
    }
    const regexForTrksidParam = new RegExp("[?&]_trksid=([^&]+).*$");
    const oldSid = url.match(regexForTrksidParam);
    if (oldSid && oldSid.length > 1) {// replace if found match
        const sidParam = `_trksid=${oldSid[1]}`;
        return url.replace(`&${sidParam}`, '').replace(sidParam, '');
    }
    return url;
  }

const doRedirectionByUrl = (destinationUrl, trksid, rootEl) => {
    if (!destinationUrl) return;
    const resultUrl = removeUrlSid(destinationUrl);
    const link = getCommonVasSpAnc(rootEl);
    if (link) {
      link.setAttribute('tabindex', -1);
      link.setAttribute('href', resultUrl);
      if (trksid) {
        link.setAttribute('_sp', trksid);
      }
      link.click();
    }
}

const doRedirection = (action, trksid, rootEl) => {
  doRedirectionByUrl(get(action, 'URL', ''),trksid,rootEl);
}


module.exports = {
    doRedirection,
    doRedirectionByUrl
};
