const { get } = require('@ebay/retriever');

const SRC_TO_COMMAND_MAP = {
    'BIN': 'shopactionsview_act_bin',
    'ATC': 'shopactionsview_act_atc'
};

const getShopactionsCsrfToken = (action, global) => {
    const src = get(action, 'params.src', '');
    const isMobile = get(global, 'isMobile', false);
    if (src) {
        const shopActionsViewCommandInfo = isMobile ? get(global, 'csrfTokens.shopactionsview', {}) : get(global, 'csrfTokenList.shopactionsview', {});
        if (shopActionsViewCommandInfo){
            return get(shopActionsViewCommandInfo, SRC_TO_COMMAND_MAP[src], "");
        }
    }
    return "";
};

module.exports = {
    getShopactionsCsrfToken
};
