$ const { get } = require('@ebay/retriever');
$ const dataSets = get(input, 'model.sections.0.dataItems', []);
<div class="vas-ctas">
    <for|button| of=dataSets>
        $ const action = button.action || {};
        $ const priority = button.type && button.type.toLowerCase();
        <if(button.action)>
            $ const href = ((!input.isBusy && action.type === 'NAV') ? action.URL : false);

            <ebay-button
                href=href
                on-click('emit', 'click', action)
                fluid
                aria-label=action.accessibilityText
                bodyState = (input.isBusy && 'loading')
                disabled = input.isDisabled
                priority=priority>
                ${button.text}
            </ebay-button>
        </if>
        <else>
            <div class='vas-ctas__ok'>
                <ux-textspans model=[button] />
            </div>
        </else>
    </for>
</div>
