const {get} = require('@ebay/retriever');

const {
    extractPostCodeRadiusValues
} = require('../../utils/searchInstaller');

module.exports = class {
    onCreate(input) {
        const radiusSearchForm = get(input, 'model.radiusSearchForm.form.entries', []);
        const values = extractPostCodeRadiusValues(radiusSearchForm);
        const postalCodeValue = values.postalCode;
        const radiusValue = values.radius;
        this.action = get(input, 'model.radiusSearchForm.cta.sections[0].dataItems[0].action', {});
        this.state = {
            disableButton: !postalCodeValue || !radiusValue,
            invalidPostalCode: !postalCodeValue,
            invalidRadius: !radiusValue,
            radiusValue: radiusValue,
            postalCodeValue: postalCodeValue
        };
    }

    handleValueEvent(eventName, {selected, value}) {
        const selectedValue = value || selected[0];
        this.state.invalidRadius = !selectedValue || selectedValue === 'null';
        this.state.radiusValue = selectedValue;

        if (!this.state.invalidRadius) {
            this.emit('value-change', this.action, {
                'name': eventName,
                'radius': selectedValue
            });
        }

        this.disableAction({invalidRadius: this.state.invalidRadius});
    }

    disableAction(error) {
        if (error.invalidPostalCode !== undefined) {
            if (error.invalidPostalCode === null) {
                error.invalidPostalCode = true;
            }
            this.state.invalidPostalCode = error.invalidPostalCode;
        }

        if (error.invalidRadius !== undefined) {
            if (error.invalidRadius === null) {
                error.invalidRadius = true;
            }
            this.state.invalidRadius = error.invalidRadius;
        }

        this.state.disableButton = this.state.invalidPostalCode || this.state.invalidRadius;
    }
}
