<finders-ti-vehicle-selection 
    model=data.model
    isLarge=!input.isMobile
    on-ti-vehicle-finder-selection('emit', 'ti-vehicle-finder-selection') />

<!-- SVG not loading through the ebay UI bcoz of shadow dom implementation -->
<div hidden aria-hidden="true">
    <svg>
        <symbol viewBox="0 0 24 24" id="icon-information-24">
            <path d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12C23.994 5.375 18.625.006 12 0Zm0 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10c-.006 5.52-4.48 9.994-10 10Zm-1-11a1 1 0 1 1 2 0v6a1 1 0 1 1-2 0v-6Zm1-3a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"/>
        </symbol>
        <symbol viewbox="0 0 24 24" fill="none" id="icon-spinner-24">
            <path d="M22.5 12A10.5 10.5 0 1 1 9.514 1.798" stroke="var(--color-spinner-icon-background, #3665F3)" stroke-width=3 stroke-linecap="round" stroke-linejoin="round"></path>
            <path d="M14.606 1.829a10.5 10.5 0 0 1 4.056 2.055 10.499 10.499 0 0 1 2.806 3.577" stroke-width=3 stroke-linecap="round" stroke-linejoin="round" stroke="var(--color-spinner-icon-foreground, #E5E5E5)"></path>
        </symbol>
        <symbol viewBox="0 0 12 12" id="icon-chevron-left-12">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.812 10.192a.625.625 0 0 0 0-.884L4.508 6l3.304-3.308a.625.625 0 1 0-.884-.884l-3.745 3.75a.625.625 0 0 0 0 .884l3.745 3.75c.244.244.64.244.884 0Z"></path>
        </symbol>
        <symbol viewBox="0 0 12 12" id="icon-chevron-right-12">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M4.183 10.192a.625.625 0 0 1 0-.884L7.487 6 4.183 2.692a.625.625 0 0 1 .884-.884l3.745 3.75a.625.625 0 0 1 0 .884l-3.745 3.75a.625.625 0 0 1-.884 0Z"></path>
        </symbol>
        <symbol viewBox="0 0 16 16" id="icon-attention-filled-16">
            <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0Zm0 12a1 1 0 1 1 0-2 1 1 0 0 1 0 2Zm1-4a1 1 0 1 1-2 0V5a1 1 0 0 1 2 0v3Z"></path>
        </symbol>
        <symbol viewBox="0 0 24 24" id="icon-information-filled-24">
            <path d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12C23.994 5.375 18.625.006 12 0Zm-1 7a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm1 3a1 1 0 0 1 1 1v6a1 1 0 1 1-2 0v-6a1 1 0 0 1 1-1Z"></path>
        </symbol>
        <symbol viewBox="0 0 16 16" id="icon-chevron-left-16">
            <path d="M3.293 8.708a1 1 0 0 1 0-1.415l6-6a1 1 0 0 1 1.414 1.414L5.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414l-6-6Z"></path>
        </symbol>
        <symbol viewBox="0 0 16 16" id="icon-chevron-right-16">
            <path d="m12.707 8.707-6 6a1 1 0 0 1-1.414-1.414L10.586 8 5.293 2.707a1 1 0 0 1 1.414-1.414l6 6a1 1 0 0 1 0 1.414Z"></path>
        </symbol>          
    </svg>
</div>  
