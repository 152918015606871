function buildEnrollmentURL(action) {
  let url = (action && action.URL) || '';
  if (action && action.params && action.URL && typeof URLSearchParams !== 'undefined') {
    const params = new URLSearchParams(action.params);
    url = `${action.URL}?${params.toString()}`;
  }
  return url;
}

function appendMissingParams(url, params) {
  const u = new URL(url);

  Object.keys(params || {}).forEach((key) => {
    if (params.hasOwnProperty(key) && !u.searchParams.get(key)) {
      u.searchParams.append(key, params[key]);
    }
  });

  return u.href;
}

module.exports = {
  buildEnrollmentURL: buildEnrollmentURL,
  appendMissingParams: appendMissingParams,
};
