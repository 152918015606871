const { get } = require('@ebay/retriever');
const { appendCustomTracking } = require('../../../utils/customEventTag');


// private method
const getRadiusFields = (radiusSearchForm) => {
    const form = radiusSearchForm && radiusSearchForm.form || {};
    const entries = form.entries || [];
    const cta = get(radiusSearchForm, 'cta.sections.0.dataItems.0', {});
    let postalField, postalCode, radiusField, radius, radiusEntries;

    entries.forEach(entry => {
        if(entry.paramKey === 'postalCode') {
            postalField = entry;
            postalCode = entry.paramValue;
            postalField.validations = form.validations || [];
        } else {
            radiusField = entry;
            radiusEntries = radiusField && radiusField.entries;
            const selectedRadius = radiusEntries.find(({selected}) => selected === true);
            radius = selectedRadius ? selectedRadius.paramValue : radiusEntries[0].paramValue;
        }
    });

    return {
        postalField,
        postalCode,
        radiusField,
        radius,
        radiusCta: cta
    };
}

// public methods

const setInstallerState = (input) => {
    const model = input.model || {};
    const modules = model.VAS_SPOKE || model.VAS_INTERSTITIAL || {};

    const installerList = modules.vasForm || [];
    const getRadiusFormFields = getRadiusFields(get(model, 'VAS_RADIUS_SEARCH.radiusSearchForm', {}));

    return {
        installerList,
        silhouettePartialScreen: false,
        postalField: getRadiusFormFields.postalField,
        postalCode: getRadiusFormFields.postalCode,
        radiusField: getRadiusFormFields.radiusField,
        radius: getRadiusFormFields.radius,
        radiusCta: getRadiusFormFields.radiusCta,
        formHeading: get(model, 'VAS_RADIUS_SEARCH.radiusSearchForm.form.heading', {}),
        silhouetteWholeScreen: false,
        selectedInstaller: input.selectedInstaller || null,
        installerError: model.VAS_INSTALLER_ERROR_MESSAGE,
        showError: input.showError || false,
        sortV2: modules.sortV2,
        mapCenterIcon: modules.mapCenterIcon,
        actions: modules.actions
    };
};

const sortTracking = (input, sortBy) => {
    const model = input.model || {};
    const modules = model.VAS_SPOKE || model.VAS_INTERSTITIAL || {};
    const sortv2 = modules.sortV2;
    const sortOptions = sortv2 && sortv2.options;
    const selectedOption = sortOptions && sortOptions.filter(option => option.paramValue === sortBy);

    return [get(selectedOption, '0.action.tracking', {})];
}

const appendEventTag = (action, isMapClick) => appendCustomTracking(action, {
        'VASFE_INSTALLER_SELECT_ISMAP': isMapClick
    })

module.exports = {
    setInstallerState,
    sortTracking,
    appendEventTag
}
