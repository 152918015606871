$ const { get } = require('@ebay/retriever');

$ {
    const global = get(input, 'options.global', {});
    const mapConfig = input.mapConfig || {};
    const isSpoke = input.isSpoke || false;
    const targetElId = get(input, 'target', '');

    let {
        visiblePage,
        introduction,
        installer,
        appointment,
        summary,
        showError,
        selectedInstaller,
        selectedDate,
        selectedSlot,
        showLoading,
    } = state;
};

<div class="parts-installation" id=(isSpoke ? "vas-spoke-container" : "vas-interstitial-container")>
    <if(visiblePage === 'introduction')>
        <parts-introduction 
            global=global 
            model=introduction
            show-error=showError
            show-loading=showLoading
            on-click('handleNextAction') 
            on-close('handleClose')
            on-sec-label-clicked('handleSecLabelClicked')
             />
    </if>

    <if(visiblePage === 'installer')>
        <parts-installer 
            global=global
            map-config=mapConfig
            show-error=showError
            selected-installer=selectedInstaller
            hide-back-icon=(!state.introduction)
            model=installer
            target-element-id=targetElId
            show-loading=showLoading
            on-installer-reload('installerUpdate')
            on-installer-click('handleInstallerClick')
            on-installer-tracking('publishTracking')
            on-back('handleBack') 
            on-close('handleClose')
            on-close-drawer('handleDrawerClose') />
    </if>

    <if(visiblePage === 'appointment')>
        <parts-scheduling 
            global=global 
            map-config=mapConfig
            show-error=showError
            selected-date=selectedDate
            selected-slot=selectedSlot
            hide-back-icon=(!state.installer)
            model=appointment
            show-loading=showLoading 
            on-click('handleSchedulingClick') 
            on-back('handleBack') 
            on-close('handleClose')
            on-publish-tracking('publishTracking') />
    </if>

    <if(visiblePage === 'summary')>
        <parts-summary 
            global=global 
            model=summary
            show-error=showError
            show-loading=showLoading
            hide-back-icon=(!state.appointment)
            on-notice-cta-click('handleNextAction')                
            on-click-card('handleNextAction')
            on-click('handleFinalAction') 
            on-back('handleBack') 
            on-close('handleClose')
            on-publish-tracking('publishTracking') />
    </if>
    <svg-hidden />            
</div>