
$ const { get } = require("@ebay/retriever");

$ {
    const model = input.model || {};
    const appointmentModule = get(model, 'VAS_APPOINTMENT_SCHEDULE', {});
    const layerCTA = get(model, 'VAS_CTA', {});
    const itemCard = get(model, 'VAS_ITEM_CARD_MINIMAL.card', {});
    const appointmentTitleSection = get(appointmentModule, 'appointmentDescription', {});
    const calendarInput = get(appointmentModule, 'calendar', {});
    const timeSlotModel = get(appointmentModule, 'time' , {});
    const slotEntries = get(appointmentModule, 'time.entries', []);
    const providerModule = get(model, 'VAS_PROVIDER', {});
    const datesInput = get(appointmentModule, 'dates', {});
    const dropOffDateLabel = get(datesInput, '__textualDropdown.prompt', '');
    const dateOptions = get(datesInput, 'entries', []);
    const footerModule = get(model, 'VAS_FOOTER', {});
    
    const personalDataModule = get(model, 'VAS_PERSONAL_DATA', {});
}

<if(!appointmentModule.isEmpty)>
    <div class="appointment">
        <item-card model=itemCard />
        <if(!providerModule.__isEmpty)>
                <provider model=providerModule/>
        </if>
        <div class="appointment__date">
            <section model=appointmentTitleSection/>
            <div class="date-dropdown">
                <ebay-select on-change("onDateDropdownSelected")
                    name='dropoff-date'
                    floating-label=dropOffDateLabel
                    class="date-dropdown__select"
                    data-testid="date-dropdown"
                >
                    <for|optionObj| of=dateOptions || []>
                        <@option
                        value=optionObj.paramValue
                        text=get(optionObj, 'label.textSpans[0].text', '')
                        selected=(optionObj.paramValue === state.selectedDate[0])
                        />
                    </for>
                </ebay-select>
            </div>

            <timeslot model=timeSlotModel on-select-slot('handleTimeSlot') enableSlots=state.enableSlots selectedSlot=state.selectedSlot/>
            <if(personalDataModule && Object.keys(personalDataModule).length !== 0)>
                <personal-data model=personalDataModule on-personal-data-change('handleLicenseChange') />
            </if>

            <div class="vas-disclosure">
                <for|entry, index| of=(get(footerModule, 'details', []))>
                    <ux-textualdisplay model=entry />
                </for>
            </div>
            
        </div>
    </div>
</if>
