import contentTemplate from './template.marko';
import pubsub from 'raptor-pubsub';


const getContainerQuery = function(input) {
  return input
    ? input.containerTargetQuery || (input.isSpoke ? '#vas-spoke-container' : '#vas-interstitial-container')
    : '';
};

const removeElement = function(parent, selector) {
  const element = parent.querySelector(selector);
  if (element) {
    parent.removeChild(element);
  }
};

const cleanRenderPreviousRenderResult = function(input) {
  const containerElQuery = getContainerQuery(input);
  const targetEl = document.getElementById(input.target);

  if (targetEl) {
    const parentEl = targetEl.shadowRoot || targetEl;
    removeElement(parentEl, containerElQuery);
  }
};

/* eslint-disable no-console */
const renderContent = function (input, template) {
  if (!input || !input.target || !input.model || !template) {
    throw new Error('no template, data or target provided to vasfe_renderContent function');
  }

  // use async render here to avoid any potential block on native refreshing inside of browser
  const contentPromise = template.render(input);
  cleanRenderPreviousRenderResult(input);
  contentPromise.then((result) => {
    const ref = document.getElementById(input.target);
    if (ref && ref.shadowRoot) {
      result.appendTo(ref.shadowRoot);
    } else if (ref) {
      result.appendTo(ref);
    }
  });
};

pubsub.on('vas-render-spoke-content', (e) => renderContent(e, contentTemplate));
pubsub.on('vas-render-interstitial-content', (e) => renderContent(e, contentTemplate));

export { renderContent };
