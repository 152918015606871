$ const { get } = require('@ebay/retriever');
import pageNotice from '<ebay-page-notice>';
import inlineNotice from '<ebay-inline-notice>';
import sectionNotice from '<ebay-section-notice>';

$ {
    const {
        model: {
            message,
            callToAction
        } = {},
        type = 'page',
        isMobile
    } = input;

    const typeMap = {
        page: pageNotice,
        inline: inlineNotice,
        section: sectionNotice
    };
    const statusMap = {
        ERROR: 'attention',
        WARNING: 'warning',
        INFORMATION: 'information',
        INFO: 'information',
        SUCCESS: 'confirmation',
    };
}
<if(message)>
    <${typeMap[type]}
        icon="none"
        a11y-text=get(message, "additionalText.0.accessibilityText")
        status=(statusMap[message.messageType])>
        <@title>
            <ux-textualdisplay model=get(message, "title", {}) />
        </@title>
        <if(!isMobile && callToAction)>
            <@footer>
                <ebay-fake-link on-click('emit', 'notice-cta-click', callToAction.action)>
                    <span>
                        ${callToAction.text}
                    </span>
                </ebay-fake-link>
            </@footer>
        </if>
        <for|additionalText| of=(get(message, "additionalText", []))>
            <p>
                <ux-textualdisplay model=additionalText />
                <if(isMobile && callToAction)>
                    <ebay-fake-link on-click('emit', 'notice-cta-click', callToAction.action)>
                        <span>
                                ${callToAction.text}
                        </span>
                    </ebay-fake-link>
                </if>
            </p>
        </for>
    </>
</if>