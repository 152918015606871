
$ const {
    image,
    iconText
} = input.model || {};

<div class="parts-scheduling__provider">
    <if(image)>
        <img src=`${image.URL}` alt=`${image.title}` width="140" height="20" class="parts-scheduling__provider-image" />
    </if>
    <if(iconText)>
        <div class="parts-scheduling__provider-text">
            <for|textSpan| of=iconText>
                <span on-click('emit', 'provider-clicked', textSpan)>
                    <ux-textualdisplay model=(textSpan && textSpan.text) />
                </span>
                $ const icon = textSpan.icon;
                <if(icon)>
                    $ const iconName = icon.name || 'external-link-16'; 
                    // to make it dynamic used the svg syntax, instaed of using the ebay-icon
                    <svg class=['icon', `icon--${iconName}`] focusable="false" height="16" width="16" role="img" aria-label=iconName>
                        <use href=`#icon-${iconName}`></use>
                    </svg>                 
                </if>
            </for>
        </div>
    </if>
</div>