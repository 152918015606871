/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
const { get } = require('@ebay/retriever');
const pubsub = require('raptor-pubsub');
const buildEnrollmentURL = require('../../common/utils').buildEnrollmentURL;

module.exports = class {
  onCreate(input) {
    const action = get(input, 'action', {});
    const enrollmentURL = buildEnrollmentURL(action);

    // initially load enrollment dialog into iframe
    this.state = {
      iframeURL: enrollmentURL,
      iframeTitle: null,
      isIframeLoaded: false, // default to isLoading
      isIdentityOverlay: false,
    };
    this.channelId = get(input, 'channelId', '');
  }

  onMount() {
    this.messageHandlerCBProxy = this.messageHandlerCB.bind(this);
    this.proxyPostMessageListener = this.postMessageListener.bind(this);
    this.proxyPostMessageListener();
  }

  onDestroy() {
    window.removeEventListener('message', this.proxyPostMessageListener);
  }

  messageHandlerCB(pageOrigin, e) {
    // Get the sent data
    const origin = e.origin || '';
    // if (pageOrigin === origin) { REMOVE THIS FOR STAGING -> https://jirap.corp.ebay.com/browse/GBLHDR-14816
    const payload = e.data || {};
    if (payload.channelId === 'vltEnrollFlow') {
      payload.data.forEach((ele) => {
        if (ele.type === 'iframe-loaded') {
          this.state.isIframeLoaded = true;
        } else if (ele.type === 'drop-client-tracking') {
          if (ele.trackingData && ele.trackingData.trackingList) {
            pubsub.channel(this.channelId).emit('tracking', ele.trackingData.trackingList);
          }
        } else if (ele.type === 'redirect-to') {
          if (ele.url) {
            window.location.href = ele.url;
          }
        } else if (ele.type === 'update-iframe-title') {
          this.state.iframeTitle = ele.title || null;
        } else if (ele.type === 'handoff-from-identity') {
          if (ele.url) {
            this.state.iframeURL = ele.url;
          }
        } else if (ele.type === 'update-dialog-size' && ele.pageKey) {
          this.updateDialogIframeSize(ele.pageKey);
        } else if (ele.type === 'reload-parent-page') {
          window.location.reload(true); /* refresh parent page */
        }
      });
    }
    // }
  }

  onCloseButtonClick(action, event) {
    pubsub.channel(this.channelId).emit('VAS_CLOSE_INTERSTITIAL');
    pubsub.channel(this.channelId).emit('VAS_CLOSE_SPOKE');
    if (action && action.trackingList) {
      pubsub.channel(this.state.channelId).emit('tracking', action.trackingList);
    }
  }

  postMessageListener() {
    const pageOrigin = location.origin || '*';
    window.addEventListener('message', (e) => this.messageHandlerCBProxy(pageOrigin, e));
  }

  updateDialogIframeSize(pageKey) {
    if (pageKey === 'enrollmentLandingPage') {
      this.state.isIdentityOverlay = false;
    }
    if (pageKey === 'enrollmentIdentityPage') {
      this.state.isIdentityOverlay = true;
    }
    if (pageKey === 'enrollmentConfirmationPage') {
      this.state.isIdentityOverlay = false;
    }
  }
};
