<!-- this template takes module _type of VasSpokeModuleV2 -->
<!-- the first usecase is VAULT -->
$ {
	const { get, has } = require('@ebay/retriever');
	const title = get(input, 'model.title', {});
	const closeIcon = get(input, 'model.closeIcon', {});
	const vasForm = get(input,'model.vasForm', []);
	const heading = get(vasForm, 'heading.textSpans[0]', []);
	const vaultRedesignType = get(input, 'viModel.VAS_META.schemaVersion.VAULT', '');
	const labelLink = get(input, 'model.vasForm[0].secondaryLabels[0]', {isEmpty: true});
	const previewPricing = get(input, 'model.vasForm[0].secondaryLabels[1]', {isEmpty: true});
	const additionalTexts = get(input, 'model.additionalTexts', [])
	let isInitFocusSet = false;
	const vaultType = vaultRedesignType === 'VAULT_NUDGE_REDESIGN' ? true : false;
	const vaultSpokeHeader = vaultType ? 'vault-spoke-header' : '';
}

<div class="vas-spoke-d" id="vas-spoke-container">
	<!-- put svgs here since shadow dom block the elements from reading svg symbols from dom outside of shadow dom -->
	<div hidden>
		<svg>
			<symbol viewBox="0 0 24 24" id="icon-attention-filled-24">
				<path fill="#e62048" fill-rule="evenodd" d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12C23.994 5.375 18.625.006 12 0zm0 18a1 1 0 110-2 1 1 0 010 2zm1-5V7a1 1 0 00-2 0v6a1 1 0 002 0z" />
			</symbol>
			<symbol viewbox="0 0 18 18" id="icon-close-18">
				<path fill="#111820" d="M10.41 9l7.294-7.287A1.004 1.004 0 0016.285.294L9 7.59 1.715.294a1.002 1.002 0 10-1.42 1.42l7.296 7.285-7.295 7.286a1 1 0 000 1.42 1 1 0 001.419 0L9 10.407l7.285 7.296a1 1 0 001.42 0 1 1 0 000-1.419l-7.296-7.286z"></path>
			</symbol>

			<symbol viewbox="0 0 18 18" id="icon-vault-16">
				<path fill-rule="evenodd" d="M17 11c0 1.02-.305 1.967-.828 2.757l1.535 1.536a1 1 0 0 1-1.414 1.414l-1.536-1.535A4.977 4.977 0 0 1 12 16a4.977 4.977 0 0 1-2.757-.828l-1.536 1.535a1 1 0 0 1-1.414-1.414l1.535-1.536A4.977 4.977 0 0 1 7 11c0-1.02.305-1.967.828-2.757L6.293 6.707a1 1 0 0 1 1.414-1.414l1.536 1.535A4.977 4.977 0 0 1 12 6c1.02 0 1.967.305 2.757.828l1.536-1.535a1 1 0 1 1 1.414 1.414l-1.535 1.536C16.695 9.033 17 9.98 17 11Zm-2 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" clip-rule="evenodd"/>
  				<path fill-rule="evenodd" d="M1 4a4 4 0 0 1 4-4h14a4 4 0 0 1 4 4v14a4.002 4.002 0 0 1-3 3.874V23a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1v-1H8v1a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-1.126A4.002 4.002 0 0 1 1 18V4Zm4-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2Z" clip-rule="evenodd"/>
			</symbol>
			<symbol viewBox="0 0 24 24" id="icon-the-ebay-vault-24">
				<path fill-rule="evenodd" clip-rule="evenodd" d="M17 11c0 1.02-.305 1.967-.828 2.757l1.535 1.536a1 1 0 0 1-1.414 1.414l-1.536-1.535A4.977 4.977 0 0 1 12 16a4.977 4.977 0 0 1-2.757-.828l-1.536 1.535a1 1 0 0 1-1.414-1.414l1.535-1.536A4.977 4.977 0 0 1 7 11c0-1.02.305-1.967.828-2.757L6.293 6.707a1 1 0 0 1 1.414-1.414l1.536 1.535A4.977 4.977 0 0 1 12 6c1.02 0 1.967.305 2.757.828l1.536-1.535a1 1 0 1 1 1.414 1.414l-1.535 1.536C16.695 9.033 17 9.98 17 11Zm-2 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"></path>
				<path fill-rule="evenodd" clip-rule="evenodd" d="M1 4a4 4 0 0 1 4-4h14a4 4 0 0 1 4 4v14a4.002 4.002 0 0 1-3 3.874V23a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1v-1H8v1a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-1.126A4.002 4.002 0 0 1 1 18V4Zm4-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2Z"></path>
			</symbol>
		</svg>
	</div>
	<if(state.showVaultEnrollmentiframe)>
		<dweb-vas-vault-enrollment-iframe closeIcon=closeIcon action=state.iframeAction channelId=input.channelId/>
	</if>
	<else>
		<div class=`vas-spoke-header ${vaultSpokeHeader}`>
			<h2 id='vas-spoke-header-text' class='vas-spoke-header-text'><ux-textualdisplay model=title /></h2>
			<if(closeIcon && closeIcon._type)>
				<button on-click('onCloseButtonClick', closeIcon.action)
					class="vas-spoke-header-close-btn vas-spoke-init-focus"
					aria-label=`${closeIcon.action ? closeIcon.action.accessibilityText : ''}`
					data-testid="close-btn">
					<!-- <ebay-close-16-icon/> is not able load svg here, so copied close icon svg here -->
					<!-- TODO: close button accessibility text and tracking -->
					<svg aria-hidden="true" class="icon icon--close-18" focusable="false" height="18" width="18">
						<use xlink:href="#icon-close-18">
						</use>
					</svg>
				</button>
				$ { isInitFocusSet = true; }
			</if>
		</div>

		<if(vaultType)>
			<div class='vas-spoke-title'>
				<span class="icon-vault" >
					<ebay-the-ebay-vault-24-icon/>
				</span>
				<span>
					<for|value, index| of=vasForm>
						$ const HeadingtextSpans = get(value, 'heading.textSpans', [])
						<for|textSpan, textSpanIdx| of=HeadingtextSpans>
							<span class='vas-header-text'>
								${textSpan.text}
							</span>
						</for>
					</for>
				</span>
			</div>
		</if>

		<div class='vas-spoke-items'>
			<for|entry, index| of=state.itemsToShow>
				<div class="vas-spoke-item" id=`vas-spoke-item-${index}`>
					<div class="vas-spoke-item-left">
						$ { const labels = ['label', 'secondaryLabel', 'tertiaryLabel', 'quaternaryLabel']; }
						<for|name| of=labels>
							<vasLabel label=entry[name] name=name index=index />
						</for>
					</div>
					<div class="vas-spoke-item-right">
						<vasLabel label=entry.accessoryLabel name='accessoryLabel' index=index />
					</div>
				</div>
			</for>

			<!-- Vault Nudge redesign Spoke Header titles -->
			<if(vaultType)>
				<div class='vas-spoke-secondary-title'>
					<vasLabel label=labelLink name=labelLink index=0 />
				</div>
				<div class='vas-spoke-tertiary-title'>
					<ux-textualdisplay model=previewPricing />
				</div>

				<!-- Vault Nudge redesign Spoke body & footer content -->

				<div class='vas-spoke-body'>
					<div class='vas-spoke-body-main'>
						<for|value, index| of=vasForm>
							$ {
							const entries = get(value, 'entries', []);
							const highAspNotice =get(value, 'entries[1].message', {});
							}
							<if(!entries.__isEmpty)>
								<for|entry, entryIndex| of=entries>
									$ const priceLines = get(entry, 'priceLines', []);
									<div class='spoke-body-label'>
										<vasLabel label=entry.label name='label' index=entryIndex/>
									</div>
									<if(!priceLines.__isEmpty)>
										<for|priceLine, priceLineIndex| of=entry.priceLines>
										$ const dataItems = get(priceLine, 'dataItems', []);
											<for|dataItem, dataItemIdx| of=dataItems>
												$ const labels = get(dataItem, 'labels', []);
												$ const values = get(dataItem, 'values', []);
												<if(labels && labels.length)>
													<div class='spoke-body-primary'>
														<div class='vas-spoke-body-primary-left'>
															<for|label, labelIdx| of=labels>
																	<ux-textualdisplay model=label />
															</for>
														</div>
														<div class='vas-spoke-body-primary-right'>
															<for|value, valueIdx| of=values>
																<ux-textualdisplay model=value />
															</for>
														</div>
													</div>
												</if>
											</for>
											<if(entryIndex === 0)>
												<div class="vault-divider" />
											</if>
										</for>
										<!-- High ASP Notice -->
										<if(!highAspNotice.__isEmpty && entryIndex === 1)>
											<div class="high-asp">
												<ebay-section-notice
													a11y-text=get(highAspNotice, "additionalText[0].accessibilityText", "")>
													<ux-textualdisplay model=(get(highAspNotice, 'additionalText[0]', {})) />
												</ebay-section-notice>
											</div>
										</if>
										<if(entryIndex === 1)>
											<div class="vault-divider" />
										</if>
									</if>
								</for>
							</if>
						</for>
					</div>
				</div>

				<!-- Vault-footer-content -->
				<for|additionalText, additionalTextIdx| of=additionalTexts>
					$ const dataItems = get(additionalText, 'dataItems', []);

					<ux-textualdisplay model=(additionalText) />
					<for|dataItem, dataItemIdx| of=dataItems>
						<div class="vas-spoke-footer">
							<vasLabel label=dataItem name='dataItems' index=dataItemIdx />
							<br/>
						</div>
					</for>
				</for>
			</if>
		</div>
	</else>
</div>

<macro|{ label, name, index }| name="vasLabel">
	<if(label)>
		<div class=[`vas-spoke-item-${name}`, `${get(label, 'action.URL', '') ? 'link-label' : ''}`] id=`vas-spoke-item-${name}-${index}`>
			<if(get(label, 'action.URL', ''))>
				<a href=get(label, 'action.URL', '') target="_blank" class=`${ !isInitFocusSet ? 'vas-spoke-init-focus' : ''}` on-click('onLabelClicked', get(label, 'action', {}))>
					<for|textSpan, textSpanIdx| of=get(label, 'textSpans', [])>
						${textSpan.text}
					</for>
				</a>
				$ { isInitFocusSet = true; }
			</if>
			<else>
				<ux-textualdisplay model=label />
			</else>
		</div>
	</if>
</macro>
