$ const {
    model: {
        label,
        secondaryLabels,
        accessoryLabels,
        tertiaryLabel, // Used for Slot name like Morning.
        tertiaryLabels, // Used for more description of the slot and It can be more than one line and font and color is different than above.
        starRating: {
            averageRating,
            count: ratingcount
        } = {},
        image : {
            URL,
            title
        } = {},
        feature
    } = {},
    number,
    select,
    variant
} = input;

$ const componentName = variant ? `installer-card-${variant}` : 'installer-card';
$ const css = (name) => `${componentName}__${name}`;
$ const round5 = (rating) => {
    if(rating) {
        const roundedValue = (Math.round(rating * 2)/2);
        return String(roundedValue).replace('.', '-');
    }
}

<span class=componentName>
    <if(URL)>
        <span class=css('left')>
            <img src=URL alt=title />
        </span>
    </if>
    <span class=css('right')>
        <span class=css('columns')>
            <span>
                <span class=css('name')>
                    <if(number)>
                        <span class=css('index')>${number}. </span>
                    </if>
                    <ux-textualdisplay model=label/>
                </span>
                <if(averageRating && ratingcount)>
                    $ const ratingValue = averageRating.value || 0;

                    <span class=css('star-rating')>
                        <if(feature)>
                            <span class=css('star-rating-average-pre-fix')>
                                ${ratingValue}
                            </span>
                        </if>
                        <ebay-star-rating disabled value=round5(ratingValue) a11yStarText=ratingValue />
                        <if(ratingcount)>
                            <span class=css('star-rating-average')>
                                (${ratingcount.value || 0})
                            </span>
                        </if>
                        <if(feature)>
                            <span class=css('feature')>
                                <ux-textualdisplay model=feature />
                            </span>
                        </if>
                    </span>
                </if>                       
                <for|item| of=(secondaryLabels || [])>
                    <span class=css('address')>
                        <ux-textualdisplay model=item/>
                    </span>
                </for>
            </span>
            <if(accessoryLabels)>
                <span class=css('accessory-label')>
                    <for|item| of=(accessoryLabels || [])>
                        <ux-textualdisplay model=item/>
                    </for>
                </span>
            </if>
        </span>
        <if(select && tertiaryLabel)>
            <span class=css('hours')>
                <ux-textualdisplay model=tertiaryLabel/>
            </span>
        </if>
        <if(tertiaryLabels)>
            <for|item| of=(tertiaryLabels || [])>
                <span class=css('dropoff__date-time')>
                    <ux-textualdisplay model=item/>
                </span>
            </for>
        </if>
    </span>
</span>
