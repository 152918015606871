$ const { get } = require('@ebay/retriever');

$ {
    const {
        model: { 
            label,
            cards,
            title
        } = {},
        variant
    } = input;
};

$ const className = variant === "vertical" ? "card-tile-vertical" : "card-tile"

<div class=className>
    <if(label || title)>
        <div class=`${className}__label`>
            <h2>
                <ux-textualdisplay model=(label || title) />
            </h2>
        </div>
    </if>

    <div class=`${className}__cards`>
        <for|card, index| of=cards>
            <if(card)>
                $ const callToActions = get(card, 'callToActions.0', {});
                $ const details = get(card, 'details', []);
                $ const subTitles = get(card, 'subTitles', []);
                <div class=`${className}__card`>                
                    <icon-image-number model={
                        icon: card.icon,
                        image: card.image,
                        index: index                
                    } />                
                    <div class=`${className}__card-right`>
                        <div>
                            <div class=`${className}__card-title`>
                                <ux-textualdisplay model=card.title/>
                            </div>
                            <if(details.length > 0)>
                                <details model={details,className}/>
                            </if>
                            <if(subTitles.length > 0)>
                                <div class=`${className}__card-subtitles`>
                                    <for |subTitle| of=subTitles>
                                        <div>
                                            <ux-textualdisplay model=subTitle/>
                                        </div>
                                    </for>
                                </div>
                            </if>                        
                        </div>
                        <if(!callToActions.__isEmpty)>
                            <ebay-icon-button class=`${className}__icon-link` on-click('emit', 'click-card', callToActions.action)>
                                <ebay-chevron-right-24-icon/>
                            </ebay-icon-button>
                        </if>
                    </div>                
                </div>
            </if>
        </for>
    </div>
</div>