const { get } = require('@ebay/retriever');
const { setInstallerState, sortTracking, appendEventTag }  = require('../helpers/installer');
const { installerSort } = require('../../../utils/sort');
const { setElementFocus } = require('../../../utils/accessibilityUtil');


module.exports = class {
    onCreate(input) {
        this.state = setInstallerState(input);
        this.targetElementId = get(input, 'targetElementId', '');
    }

    onInput(input) {
        this.state.showError = input.showError || false;
        this.state.silhouetteWholeScreen = input.showLoading || false;
    }

    handleRadiusChange(action, { name, radius, postalCode }) {
        if(radius) {
            this.state.radius = radius;
        }

        if(postalCode) {
            this.state.postalCode = postalCode;
        }

        if (name === 'keyDown' && action) {
            this.getInstallers(action);
        }
    }

    handleSortResults({ sortBy }) {
        this.state.installerList = this.state.installerList.slice().sort(installerSort[sortBy] || installerSort.DEFAULT);
        this.emit('installer-tracking', sortTracking(this.input, sortBy));
    }
    
    getInstallers({ action }) {
        this.state.silhouettePartialScreen = true;
        
        if (action) {
            this.emit('installer-tracking', get(action, 'trackingList', []));
            // Abort controller for timeout
            const controller = new AbortController();
            const id = setTimeout(() => {
                controller.abort();
            }, (action.timeout || 6000));

            // Input data for the request
            const url = action.url || action.URL || '';

            const params = action.params || {};
            const uxComponent = params.supportedUxComponents;

            // VASSELECTION PARAMS
            Object.assign(params, {
                vasSelectionParams: get(this, `input.model.VAS_DATA.dataItems`, [])
            });

            const vasSelectionParams = params.vasSelectionParams || [];

            vasSelectionParams.forEach(vasParam => {
                Object.assign(vasParam, {
                    postalCode: this.state.postalCode,
                    radius: this.state.radius
                });
            });

            if(uxComponent && !Array.isArray(uxComponent)) {
                params.supportedUxComponents = [uxComponent];
            }

            fetch(url, {
                signal: controller.signal,
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify(params)
            }).then(response => {
                this.state.showError = !response.ok;

                return response.json();
            }).then(response => {
                if (response && Object.keys(response).length && !response.error) {
                    const installerError = response.VAS_INSTALLER_ERROR_MESSAGE;

                    if(installerError) {
                        // this.state.installerError = installerError;
                        this.state = setInstallerState({model: response});
                        this.emit('installer-reload', response);
                        // this.state.installerList = [];
                        this.setErrorFocus();
                    } else {
                        const modules = response.VAS_SPOKE || response.VAS_INTERSTITIAL || {};
                        const sortComponet = this.getComponent('sort');

                        this.state.installerError = null;
                        this.state.installerList = modules.vasForm || [];

                        if(sortComponet) {
                            sortComponet.state.value = '';
                        }
                                                          
                        this.emit('installer-reload', response);
                    }
                } else {
                    this.state.showError = true;
                }
            }).catch(() => {
                this.state.showError = true;
                this.setErrorFocus(true);
            }).finally(() => {
                this.state.silhouettePartialScreen = false;
                clearTimeout(id);
            });
        }
    }

    setErrorFocus(errorScreen){
        const focusElement = errorScreen ? '.error-screen__content p > span:first-child' : '.section-notice  .section-notice__title > span:first-child';
        setTimeout(()=>{
            // For PI, It is always first element
            setElementFocus(focusElement, this.targetElementId);
        })
    }

    installerClick(actions, isMapClick, selectedInstaller) {
        this.state.silhouetteWholeScreen = true;
        this.emit('installer-click', 
            appendEventTag(get(actions, '0', {}), isMapClick), 
            selectedInstaller);
    }

    markerClick(installer) {
        this.state.selectedInstaller = installer;
    }
};