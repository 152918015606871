$ {
	const { get, has } = require('@ebay/retriever');
	const closeIcon = get(input, 'closeIcon', {});
}

<div class="vas-interstitial-enrollment">
	<!-- put svgs here since shadow dom block the elements from reading svg symbols from dom outside of shadow dom -->
	<div hidden data-testid="SVGS">
		<svg>
			<symbol viewBox="0 0 24 24" id="icon-attention-filled-24">
				<path fill="#e62048" fill-rule="evenodd" d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12C23.994 5.375 18.625.006 12 0zm0 18a1 1 0 110-2 1 1 0 010 2zm1-5V7a1 1 0 00-2 0v6a1 1 0 002 0z" />
			</symbol>
			<symbol viewbox="0 0 18 18" id="icon-close-16">
				<path fill="#111820" d="M10.41 9l7.294-7.287A1.004 1.004 0 0016.285.294L9 7.59 1.715.294a1.002 1.002 0 10-1.42 1.42l7.296 7.285-7.295 7.286a1 1 0 000 1.42 1 1 0 001.419 0L9 10.407l7.285 7.296a1 1 0 001.42 0 1 1 0 000-1.419l-7.296-7.286z"></path>
			</symbol>
			<symbol id="icon-spinner-24" viewBox="0 0 40 40">
				<path fill-rule="evenodd" clip-rule="evenodd" d="M20 3C16.6377 3 13.351 3.99703 10.5553 5.86502C7.75968 7.733 5.58075 10.388 4.29406 13.4944C3.00737 16.6007 2.67071 20.0189 3.32666 23.3165C3.98261 26.6142 5.6017 29.6433 7.97919 32.0208C10.3567 34.3983 13.3858 36.0174 16.6835 36.6734C19.9812 37.3293 23.3993 36.9926 26.5056 35.706C29.612 34.4193 32.267 32.2403 34.135 29.4447C36.003 26.6491 37 23.3623 37 20C37 19.1716 37.6716 18.5 38.5 18.5C39.3284 18.5 40 19.1716 40 20C40 23.9556 38.827 27.8224 36.6294 31.1114C34.4318 34.4004 31.3082 36.9638 27.6537 38.4776C23.9992 39.9913 19.9778 40.3874 16.0982 39.6157C12.2186 38.844 8.65492 36.9392 5.85787 34.1421C3.06082 31.3451 1.15601 27.7814 0.384303 23.9018C-0.387401 20.0222 0.00866568 16.0009 1.52242 12.3463C3.03617 8.69181 5.59962 5.56824 8.8886 3.37061C12.1776 1.17298 16.0444 0 20 0C20.8284 0 21.5 0.671573 21.5 1.5C21.5 2.32843 20.8284 3 20 3Z" fill="var(--color-spinner-icon-foreground, #E5E5E5)"/>
				<path fill-rule="evenodd" clip-rule="evenodd" d="M23.3386 1.74203C23.553 0.941827 24.3755 0.466954 25.1757 0.681366C28.5668 1.59001 31.659 3.37528 34.1415 5.85775C36.6239 8.34021 38.4092 11.4324 39.3179 14.8235C39.5323 15.6237 39.0574 16.4462 38.2572 16.6606C37.457 16.875 36.6345 16.4002 36.4201 15.6C35.6477 12.7175 34.1302 10.0892 32.0201 7.97907C29.9101 5.86897 27.2817 4.35149 24.3993 3.57914C23.5991 3.36473 23.1242 2.54223 23.3386 1.74203Z" fill="var(--color-spinner-icon-background, #3665F3)"/>
			</symbol>
		</svg>
	</div>
	<if(!state.isIframeLoaded)>
		<div>
			<div class="full-page-overlay"></div>
			<div class="full-spinner" data-testid="SPINNER">
				<span class="progress-spinner progress-spinner--large" aria-label="Busy" role="img">
					<svg aria-hidden="true" class="icon icon--spinner-24" focusable="false">
						<use xlink:href="#icon-spinner-24"></use>
					</svg>
				</span>
			</div>
		</div>
	</if>
	<div class="vas-interstitial-enrollment-wrapper">
		<div class="vas-interstitial-enrollment-wrapper-div">
			<if(!state.isIdentityOverlay && closeIcon && closeIcon._type)>
				<button on-click('onCloseButtonClick', closeIcon.action)
					class="vas-interstitial-enrollment-close-btn"
					aria-label=`${closeIcon.action ? closeIcon.action.accessibilityText : ''}`>
					<svg aria-hidden="true" class="icon icon--close-16" focusable="false" height="26" width="26">
								<use xlink:href="#icon-close-16">
								</use>
					</svg>
				</button>
			</if>
		</div>
	</div>
	<iframe
		style={ display: state.isIframeLoaded? 'block' : 'none'}
		src=state.iframeURL
		class=[ state.isIdentityOverlay ? "vas-interstitial-enrollment-iframe__identity" : "vas-interstitial-enrollment-iframe" ]
		id="vas-interstitial-enrollment-iframe"
		title=state.iframeTitle
		frameborder="0"
		data-testid="ENROLLMENT_IFRAME">
	</iframe>
</div>
