/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
const pubsub = require('raptor-pubsub');
const { get } = require('@ebay/retriever');
const { appendMissingParams } = require('../../common/utils');

// no add item selected when user land on warranty interstitial overlay
module.exports = class {
  onCreate(input) {
    const qualifyingVariant = input.qualifyingVariant;
    const entries = get(input, 'model.vasForm', []);
    const cta = get(input, 'model.sections[0].dataItems', []);
    const srt = get(input, 'model.srt', '');
    const tempVasType = get(input, 'model.modules.VAS_META.eligibleVASTypes[0]');

    this.state = {
      qualifyingVariant: qualifyingVariant, // msku variation Id
      itemsToShow: this.getItemsToShow(entries, qualifyingVariant), // VAS items
      cta: cta, // call to action model, data module for interstitital btns
      srt: srt,
      channelId: input.channelId,
      isBusy: false,
      btnClickedIdx: -1,
      showVaultEnrollmentiframe: false,
      tempVasType: tempVasType,
      iframeAction: {},
    };

    this.proxyCloseSpoke = this.closeSpoke.bind(this);
  }

  onMount() {
    const trackingList = get(this.input, 'model.meta.trackingList', []);
    pubsub.channel(this.input.channelId).emit('tracking', trackingList);

    window.addEventListener('pageshow', this.proxyCloseSpoke);
  }

  onDestroy() {
    window.removeEventListener('pageshow', this.proxyCloseSpoke);
  }

  onCloseButtonClick(action, event) {
    pubsub.channel(this.state.channelId).emit('VAS_CLOSE_SPOKE');
    if (action && action.trackingList) {
      pubsub.channel(this.state.channelId).emit('tracking', action.trackingList);
    }
  }

  closeSpoke(event) {
    // in case there is no browser auto refresh when user clicks go back/forward button on browser
    const historyTraversal =
      event.persisted || (typeof window.performance !== 'undefined' && window.performance.navigation.type === 2);
    if (historyTraversal) {
      pubsub.channel(this.state.channelId).emit('VAS_CLOSE_SPOKE');
    }
  }

  loadEnrollmentiFrame() {
    this.state.showVaultEnrollmentiframe = true;
  }

  onLabelClicked(action, event) {
    event.preventDefault();
    if (!action) {
      return;
    }
    if (action.trackingList) {
      pubsub.channel(this.state.channelId).emit('tracking', action.trackingList);
    }

    if (action.params && action.params.iframe === '1') {
      this.state.showVaultEnrollmentiframe = true;
      this.state.iframeAction = action;
    } else if (action.URL) {
      if (action.clientPresentationMetadata && action.clientPresentationMetadata.presentationType === 'OPEN_VIEW') {
        // in case of PPA user https://jirap.corp.ebay.com/browse/VASVC-1864
        const targetUrl = appendMissingParams(action.URL, action.params);
        window.location.assign(targetUrl);
        return;
      }
      window.open(action.URL, '_blank');
    }
  }

  getItemsToShow(entries, qualifyingVariant) {
    // backend ensures that only one entry is there
    if (entries.length === 1) {
      return entries;
    }
    return entries.filter(
      (entry) =>
        !qualifyingVariant ||
        (!!qualifyingVariant && entry.filter && entry.filter.values && entry.filter.values.includes(qualifyingVariant))
    );
  }
};
