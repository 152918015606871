const setElementFocus = (focusElementQuery, targetElementId) => {
    let elemtToFocus = null;
    const targetEl = document.getElementById(targetElementId);
    if (targetEl) {
        if (targetEl.shadowRoot) {
            elemtToFocus = targetEl.shadowRoot.querySelector(focusElementQuery);
        } else {
            // Fallback if shadowDOM not available
            elemtToFocus = document.querySelector(focusElementQuery);
        } 
    }
    if (elemtToFocus) {
        elemtToFocus.setAttribute('tabindex', 0);
        elemtToFocus.focus();
    }
}

module.exports = {
    setElementFocus
};
