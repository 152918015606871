<for|footerText| of=input.model>
    <div class="parts-summary__footer-text">
        <p>
            <for|textSpan| of=footerText.textSpans>
                <span on-click('emit', 'provider-clicked', textSpan)>
                    <ux-textspans model=[textSpan] />
                </span>
            </for>                    
        </p>
    </div>
</for>