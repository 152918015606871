$ {
	const { get } = require('@ebay/retriever');
	const vaultType = get(input, 'model.modules.VAS_META.schemaVersion.VAULT', '');
	const eligibleVASType = get(input, 'model.modules.VAS_META.eligibleVASTypes[0]', '');
  let isInitFocusSet = false;
	const iconMapping = {
		'BADGE_ROSETTE_WITH_TICK': 'AUTHENTICITY-GUARANTEE',
		'LOCK_OUTLINE': 'ESCROW',
		'SELLING_TAG': 'SELLING',
		'TOP_SERVICE': 'TOP-SERVICE',
	};
	const ctaDataItems = state.ctaSections.reduce((acc, section) => acc.concat(section.dataItems), []);
}

<if(vaultType === 'VAULT_NUDGE_REDESIGN')>
  $ {
    const titleText = get(input, 'model.modules.VAS_INTERSTITIAL_MODULE.title.textSpans[0].text', '');
    const closeIcon = get(input, 'model.modules.VAS_INTERSTITIAL_MODULE.closeIcon', {});
    const vasForm = get(input, 'model.modules.VAS_INTERSTITIAL_MODULE.vasForm', []);
    const additionalTexts = get(input, 'model.modules.VAS_INTERSTITIAL_MODULE.additionalTexts', []);
    const itemCard = get(input, 'model.modules.VAS_ITEM_CARD_MINIMAL.card', {});
  }

  <div class="vas-warranty-interstitial-nudge-d vault-redesign-interstitial-d" id="vas-interstitial-container">
		<svg-hidden />
    <if(state.showVaultEnrollmentiframe)>
			<dweb-vas-vault-enrollment-iframe closeIcon=closeIcon action=state.iframeAction channelId=input.channelId/>
		</if>
		<else>
			<div class='vas-interstitial-header'>
				<h2 id='vas-interstitial-header-text' class='vas-interstitial-header-text'>${titleText}</h2>
				<if(closeIcon && closeIcon._type)>
					<button on-click('onCloseButtonClick', closeIcon.action)
						class="vas-interstitial-header-close-btn vas-interstitial-init-focus"
						aria-label=`${closeIcon.action ? closeIcon.action.accessibilityText : ''}`
						>
						<!-- <ebay-close-16-icon/> is not able load svg here, so copied close icon svg here -->
						<!-- TODO: close button accessibility text and tracking -->
						<svg aria-hidden="true" class="icon icon--close-18" focusable="false" height="18" width="18">
							<use xlink:href="#icon-close-18">
							</use>
						</svg>
					</button>
					$ { isInitFocusSet = true; }
				</if>
			</div>

			<div class='vas-interstitial-body'>

				<div class="vas-spoke-item-card">
					<vas-item-card model=itemCard/>
				</div>
				<for|form, formIdx| of=vasForm>
					$ const vaultHeading = get(form, 'heading.textSpans', []);
					$ const label = get(form, 'label', {})
					$ const secondaryLabels = get(form, 'secondaryLabels', []);
					$ const entries = get(form, 'entries', []);

					<div class='vas-divider' />

					<!-- vault icon & The ebay Vault -->
					<div class="vas-heading">
						<span class="icon-vault" >
							<ebay-the-ebay-vault-24-icon/>
						</span>

						<span class="heading-text">
							<for|heading| of=vaultHeading>
								${heading.text}
							</for>
						</span>
					</div>

					<!-- Label: When you purchase this item -->
					<ux-textualdisplay model=label/>

					<!-- Learn More link -->
					<for|label, labelIdx| of=secondaryLabels>
						<div class="vas-secondary-labels">
							<vasLabel label=label name='secondaryLabels' index=labelIdx />
						</div>
					</for>

					<fieldset>
						<for|entry,idx| of=entries>
							<span class="field">
								<ebay-radio
									class="field__control"
									id=`group-radio-${entry.paramKey}-${entry.paramValue}`
									value=state.addonsSelectionMap
									name=entry.paramKey
									type="radio"
									checked =(state.addonsSelectionMap === entry.paramValue)
									on-change("onRadioSelected",entry)
									/>
								<label
									class="field__label field__label--end"
									for=`group-radio-${entry.paramKey}-${entry.paramValue}`>
										<ux-textualdisplay model=entry.label />
								</label>
							</span>
							<div class="field-secondory-label">
								$ const radioLabel = get(entry, 'secondaryLabel', {})
									<ux-textualdisplay model=radioLabel />
							</div>

						</for>
					</fieldset>
				</for>

				<for|form, formIdx| of=vasForm>
					$ {
						const entries = get(form, 'entries', []);
						const highAspNotice = get(entries[1], 'message', {});
					}

					<for|entry,idx| of=entries>
							$ const dataItems = get(entry, 'priceLines[0].dataItems', [])
							<if(state.addonsSelectionMap === entry.paramValue)>
								<for|value, index| of=dataItems>
									<vasLabelsValues model=value index=index />
								</for>
							</if>
					</for>

					<!-- High ASP message-->
					<if(Array.isArray(entries) && entries[1] && entries[1].message && state.highAspMsg)>
						<div class="high-asp">
							<ebay-section-notice
								a11y-text=get(highAspNotice, "additionalText[0].accessibilityText","")>
								<ux-textualdisplay model=(get(highAspNotice, 'additionalText[0]', {})) />
							</ebay-section-notice>
						</div>
					</if>
				</for>
			</div>

			<!-- Footer content -->
			<div class='vas-interstitial-footer'>
				<for|additionalText, additionalTextIdx| of=additionalTexts>
					$ const dataItems = get(additionalText, 'dataItems', []);

					<ux-textualdisplay model=(additionalText) />
					<for|dataItem, dataItemIdx| of=dataItems>
						<ux-textualdisplay model=dataItem />
					</for>
				</for>
			</div>

			<div class='vas-interstitial-vault-cta'>
				<for|entry, index| of=state.cta>
					<ebay-button
						class=["vas-interstitial-cta-btn", `${ !isInitFocusSet ? 'vas-interstitial-init-focus' : '' }`]
						id=`vas-interstitial-cta-${entry.text ? entry.text.toLowerCase() : index}`
						priority=`${entry.type ? entry.type.toLowerCase() : 'primary'}`
						on-click("onCTAButtonClick", entry.action, index)
						disabled=state.isBusy
						aria-label=(entry.accessibilityText ? entry.accessibilityText : '')>
						<if(state.btnClickedIdx === index)>
							<ebay-progress-spinner class="vas-interstitial-cta-btn-spinner" aria-label="Busy" />
						</if>
						<else>
							${entry.text}
						</else>
					</ebay-button>
					$ { isInitFocusSet = true; }
				</for>
			</div>
		</else>
    
  </div>
</if>
<else-if(vaultType === 'VAULT_ADOPTION')>
	$ {
    const titleImg = get(input, 'model.modules.VAS_HEADER_MODULE.image', {});
    const titleText = get(input, 'model.modules.VAS_HEADER_MODULE.title.textSpans[0].text', '');
    const closeIcon = get(input, 'model.modules.VAS_HEADER_MODULE.closeIcon', {});
    const additionalTexts = get(input, 'model.modules.VAS_INTERSTITIAL_MODULE.additionalTexts', []);
  }

  <div class="vas-warranty-interstitial-nudge-d vault-adoption-interstitial-d" id="vas-interstitial-container">
    <svg-hidden />
    <div class='vas-interstitial-header vault-adoption-interstitial-header'>
			<img src=`${titleImg.URL}` alt=`${titleImg.title}` class='vas-interstitial-header-img' id='vas-interstitial-header-img'/>
			<if(closeIcon && closeIcon._type)>
				<button on-click('onCloseButtonClick', closeIcon.action)
					class="vas-interstitial-header-close-btn vas-interstitial-init-focus"
					aria-label=`${closeIcon.action ? closeIcon.action.accessibilityText : ''}`
					>
					<svg aria-hidden="true" class="icon icon--close-18" focusable="false" height="18" width="18">
						<use xlink:href="#icon-close-18">
						</use>
					</svg>
				</button>
				$ { isInitFocusSet = true; }
			</if>
		</div>

		<div class='vas-interstitial-body vault-adoption-interstitial-body'>
			<div class='vas-interstitial-body__title'>
				${titleText}
			</div>
		</div>

		<div class="vas-cards-section">
      <for|card| of=get(input, 'model.modules.VAS_INTERSTITIAL_MODULE.cards', [])>
        <renderCard card=card />
      </for>
    </div>

		<div class='vas-interstitial-footer vault-adoption-interstitial-footer'>
			<for|additionalText| of=additionalTexts>
				$ const dataItems = get(additionalText, 'dataItems', []);
				<for|dataItem| of=dataItems>
					<span class="ux-textspans--SECONDARY">${dataItem.textSpans[0].text} </span>
					<a href=dataItem.action.URL class="ux-textspans--INLINE_LINK">
            ${dataItem.textSpans[2].text}
          </a>
				</for>
			</for>
		</div>

		<div class='vas-divider' />

		<div class='vas-interstitial-vault-cta vault-adoption-interstitial-cta'>
			$ const secondarySection = state.ctaSections[1];
			$ const primarySection = state.ctaSections[0];
			<for|entry, index| of=secondarySection.dataItems>
				<ebay-button
					class=["vas-interstitial-cta-btn", `${ !isInitFocusSet ? 'vas-interstitial-init-focus' : '' }`]
					id=`vas-interstitial-cta-${entry.text ? entry.text.toLowerCase() : index}`
					priority=`${entry.type ? entry.type.toLowerCase() : 'primary'}`
					on-click("onCTAButtonClick", entry.action, 0)
					disabled=state.isBusy
					aria-label=(entry.accessibilityText ? entry.accessibilityText : '')>
					<if(state.secondaryBtnClickedIdx === index)>
						<ebay-progress-spinner class="vas-interstitial-cta-btn-spinner" aria-label="Busy" />
					</if>
					<else>
						${entry.text}
					</else>
				</ebay-button>
				$ { isInitFocusSet = true; }
			</for>
			<for|entry, index| of=primarySection.dataItems>
				<div class="vas-interstitial-wrapper">
					<ebay-button
						class=["vas-interstitial-cta-btn", `${ !isInitFocusSet ? 'vas-interstitial-init-focus' : '' }`]
						id=`vas-interstitial-cta-${entry.text ? entry.text.toLowerCase() : index}`
						priority=`${entry.type ? entry.type.toLowerCase() : 'primary'}`
						on-click("onCTAButtonClick", entry.action, 1)
						disabled=state.isBusy
						aria-label=(entry.accessibilityText ? entry.accessibilityText : '')>
						<if(state.primaryBtnClickedIdx === index)>
							<ebay-progress-spinner class="vas-interstitial-cta-btn-spinner" aria-label="Busy" />
						</if>
						<else>
							<if(entry.ctaIcon)>
								<svg class="icon icon--the-ebay-vault-16" id="vault-icon" focusable="false" height="16" width="16">
									<use xlink:href="#icon-the-ebay-vault-16"></use>
								</svg>
							</if>
							${entry.text}
						</else>
					</ebay-button>
				</div>
				$ { isInitFocusSet = true; }
			</for>
		</div>
  </div>
</else-if>
<else-if(eligibleVASType === 'GRADING')>
	$ {
    const headerTitle = get(input, 'model.modules.VAS_HEADER_MODULE.title.textSpans[0].text', '');
		const headerIcon = get(input, 'model.modules.VAS_HEADER_MODULE.icon', {});
		const bodyTitleText = get(input, `model.modules.${state.moduleName}.title.textSpans[0].text`, '');
    const closeIcon = get(input, 'model.modules.VAS_HEADER_MODULE.closeIcon', {});
    const additionalTexts = get(input, `model.modules.${state.moduleName}.additionalTexts`, []);
  }

  <div class="vas-warranty-interstitial-nudge-d vault-adoption-interstitial-d" id="vas-interstitial-container">
    <svg-hidden />
    <div class='vas-interstitial-header vault-adoption-interstitial-header'>
			<if(headerIcon && headerIcon._type)>
				<svg class=`icon icon--${headerIcon.name.toLowerCase()}-16` aria-hidden="true" focusable="false" height="16" width="45">
					<use xlink:href=`#icon-${headerIcon.name.toLowerCase()}-16`></use>
				</svg>
			</if>
			<if(headerTitle)>
				<div class="vas-interstitial-header-title">
					${headerTitle}
				</div>
			</if>
			<if(closeIcon && closeIcon._type)>
				<button on-click('onCloseButtonClick', closeIcon.action)
					class="vas-interstitial-header-close-btn vas-interstitial-init-focus"
					aria-label=`${closeIcon.action ? closeIcon.action.accessibilityText : ''}`
					>
					<svg aria-hidden="true" class="icon icon--close-18" focusable="false" height="18" width="18">
						<use xlink:href="#icon-close-18">
						</use>
					</svg>
				</button>
				$ { isInitFocusSet = true; }
			</if>
		</div>

		<div class='vas-interstitial-body vault-adoption-interstitial-body'>
			<div class='vas-interstitial-body__title'>
				${bodyTitleText}
			</div>
		</div>

		<div class="vas-cards-section">
      <for|card| of=get(input, `model.modules.${state.moduleName}.cards`, [])>
        <renderCard card=card />
      </for>
    </div>

		<div class='vas-interstitial-footer vault-adoption-interstitial-footer'>
			<for|additionalText| of=additionalTexts>
				$ const dataItems = get(additionalText, 'dataItems', []);
				<for|dataItem| of=dataItems>
					<p>
						<ux-textualdisplay model=dataItem />
					</p>
				</for>
			</for>
		</div>

		$ {
			// Clone and sort so that primary buttons (type "primary") come last.
			const sortedCTA = ctaDataItems && ctaDataItems.slice().sort((a, b) => {
				const aPrimary = (a.type && a.type.toLowerCase() === 'primary') ? 1 : 0;
				const bPrimary = (b.type && b.type.toLowerCase() === 'primary') ? 1 : 0;
				return aPrimary - bPrimary;
			});
		}
		<if(sortedCTA.length !== 0)>
			<div class='vas-divider' />
			
			<div class='vas-interstitial-vault-cta vault-adoption-interstitial-cta'>
				<for|cta, index| of=sortedCTA>
					<ebay-button
						class=["vas-interstitial-cta-btn", `${ !isInitFocusSet ? 'vas-interstitial-init-focus' : '' }`]
						id=`vas-interstitial-cta-${cta.text ? cta.text.toLowerCase() : index}`
						priority=`${cta.type ? cta.type.toLowerCase() : 'primary'}`
						on-click("onGradingCTAButtonClick", cta.action, (cta.type && cta.type.toLowerCase() === 'primary') ? true : false)
						disabled=state.isBusy
						aria-label=(cta.accessibilityText ? cta.accessibilityText : '')>
							${cta.text}
					</ebay-button>
				</for>
			</div>
		</if>
  </div>
</else-if>

<macro|{ label, name, index }| name="vasLabel">
	<if(label)>
        <div class=[`vas-interstitial-item-${name}`, `${get(label, 'action.URL', '') ? 'link-label' : ''}`] id=`vas-interstitial-item-${name}-${index}`>
			<if(get(label, 'action.URL', ''))>
				<a href=get(label, 'action.URL', '') target="_blank" class=`${ !isInitFocusSet ? 'vas-interstitial-init-focus' : ''}` on-click('onLabelClick', get(label, 'action', {}))>
					<for|textSpan, textSpanIdx| of=get(label, 'textSpans', [])>
						${textSpan.text}
					</for>
				</a>
				$ { isInitFocusSet = true; }
			</if>
			<else>
				<ux-textualdisplay model=label />
			</else>
		</div>
	</if>
</macro>

<macro|dataItem, index| name="vasLabelsValues">
	$ const labels = get(dataItem, 'model.labels', []);
	$ const values = get(dataItem, 'model.values', [])

    <div class='spoke-body-primary'>
        <div class='vas-spoke-body-primary-left'>
            <for|label, labelIdx| of=labels>
                    <ux-textualdisplay model=label />
            </for>
        </div>

        <div class='vas-spoke-body-primary-right'>
            <for|value, valueIdx| of=values>
                <ux-textualdisplay model=value />
            </for>
        </div>
    </div>
</macro>

<macro|{ card }| name="renderCard">
  <div class="vas-card">
		$ const iconName = get(iconMapping, card.icon.name, card.icon.name).toLowerCase();
    <svg class=`icon icon--${iconName}-24` aria-hidden="true" focusable="false" height="24" width="24">
      <use xlink:href=`#icon-${iconName}-24`></use>
    </svg>
    <div class="vas-card-content">
      <div class="vas-card-content-title">${card.title.textSpans[0].text}</div>
      <div class="vas-card-content-description">${card.details[0].textSpans[0].text}</div>
    </div>
  </div>
</macro>
