'use strict';

const googleUtil = require('./google-util');
const defaultLanguage = 'en';
const defaultCountry = 'US';
const defaultRadius = 25;

class InstallerMap {
    onUpdate() {
        const {
            input: {
                center: {
                    latitude: clat,
                    longitude: clng
                } = {},
                center,
                country = defaultCountry,
                model,
                radius = defaultRadius,
                selectedInstaller,
                showSelectedMarkerOnly,
                showInfo
            } = {},
            map
        } = this;

        if (map && model) {
            // remove the old markers
            googleUtil.removeMarkers(map);

            // Add center marker at center and test for refit
            if (clat && clng) {
                googleUtil.addMarker(map, { type: 'center', center, radius, country });
            }

            if (showSelectedMarkerOnly) {
                const mapData = this.getInstallerMapData(selectedInstaller);
                googleUtil.addMarker(map, mapData);
            } else {
                // push updated markers to the map
                model.forEach(installer => {
                    const mapData = this.getInstallerMapData(installer);
                    googleUtil.addMarker(map, mapData);
                });
            }
            // center map on selected installer
            if (selectedInstaller) {
                const {
                    vasValues: {
                        latitude,
                        longitude
                    } = {}
                } = selectedInstaller;

                const lat = parseFloat(latitude);
                const lng = parseFloat(longitude);

                /* istanbul ignore else: xs has contract to provide */
                if ((lat && lng) && showInfo !== 'info-label') {
                    map.panTo({ lat, lng });
                }
            }

            // fit-bounds if required
            const idList = model.map(installer => installer.paramValue).sort().join('-');
            const fitHash = `${idList}-${model.length}-${radius}-${clng}-${clat}`;

            if (fitHash !== this.fitHash) {
                googleUtil.setBounds(map, { center, radius, country });
            }
            this.fitHash = fitHash;

            if(showInfo === 'info-label') {
                setTimeout(() => {
                    if(selectedInstaller && this.map) {
                        const idValue = selectedInstaller.paramValue || '';
                        const isSelected = Array.isArray(model) && model.filter((id) => id.paramValue === idValue);
                        if(isSelected.length) {
                            googleUtil.showInfoView(this.map, 0, this.getEl(`info-card-${idValue}`));
                        }
                    }
                }, 600);
            }            
        }

    }

    getInstallerMapData(installer) {
        const {
            paramValue: id,
            vasValues: {
                latitude, // 37.32627,
                longitude // 121.94975
            } = {}
        } = installer;
        const data = { latitude, longitude, el: this.getEl(id) }
        return data;
    }

    onMount() {
        const { apiKey = '', country = defaultCountry, language = defaultLanguage } = this.input;

        googleUtil.loadMapsApi(apiKey, country.toLowerCase(), language).then(api => {
            this.api = api;
            this.map = googleUtil.initMap(this.getEl('google-map'));

            this.forceUpdate();
        });
    }

    handleInstallerClick(selected) {
        this.emit('installer-click', selected);
    }

    handleMapInfoView(installer, index) {
        const idValue = installer.paramValue || '';
        this.emit('marker-click', installer, index);
        setTimeout(() => googleUtil.showInfoView(this.map, index, this.getEl(`info-card-${idValue}`)),500);
    }
}

module.exports = InstallerMap;
