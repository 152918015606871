
$ const { get } = require('@ebay/retriever');
$ const {
    details,
    className
} = input.model || {};

<if(details.length > 0)>
    <for |detail| of=details>
        $ const detailType = get(detail,'_type','');
        <if(detailType === 'TextualDisplay')>
            <div class=`${className}__card-details`>
                <ux-textualdisplay model=detail/>
            </div>
        </if>
        <else-if(detailType === 'UnorderedList')>
            <if(detail.content && detail.content.length > 0)>
                <div class=`${className}__card-details`>
                    <ul>
                        <for |content| of=detail.content>
                            <li>
                                <ux-textualdisplay model=content/>
                            </li>
                        </for>
                    </ul>
                </div>
            </if>
        </else-if>
    </for>
</if>
