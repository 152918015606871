'use strict';
/* global google */
const off = false;
let infoWindow;
const infoElementCache = [];
const on = true;
const hide = [{ visibility: 'off' }];
const mapConfig = {
    center: { lat: 0, lng: 0 },
    fullscreenControl: off,
    mapTypeControl: off,
    mapTypeId: "roadmap",
    rotateControl: off,
    scaleControl: off,
    streetViewControl: off,
    styles: [
        { featureType: 'administrative.land_parcel', elementType: 'labels', stylers: hide },
        { featureType: 'administrative.neighborhood', elementType: 'labels', stylers: hide },
        { featureType: 'administrative', elementType: 'geometry', stylers: hide },
        { featureType: 'landscape.natural', elementType: 'labels', stylers: hide },
        { featureType: 'poi', elementType: 'labels.text', stylers: hide },
        { featureType: 'poi', stylers: hide },
        { featureType: 'road.local', elementType: 'labels', stylers: hide },
        { featureType: 'road', elementType: 'labels.icon', stylers: hide },
        { featureType: 'transit', stylers: hide }
    ],
    zoom: 13,
    zoomControl: on
};
const cache = { mapApiPromise: {} };
// todo: Create component <google-maps-loader locale="en-US" api-key=googleApiKey on-load=handleMapLoad />
const defaultGoogleKey = ''; // see https://console.cloud.google.com/apis/credentials to remove dev only warning
const assign = (o, values) => Object.assign(o, values);
/**
 *
 * @param {string} apiKey the google maps api key used
 * @param {string} country a two letter country code based on the siteId
 * @param {string} language a two letter language code. you can derive from the `<html lang` tag.
 *  "If you set the language of the map, it's important to consider setting the region too. This helps
 *  ensure that your application complies with local laws."
 *  See https://developers.google.com/maps/documentation/javascript/localization for more info
 * @returns
 */
const loadMapsApi = async (apiKey, country, language) => {
    const localeKey = `${language}-${country}`;

    // if !cached add the promise to the cache and load the script
    if (!cache.mapApiPromise[localeKey]) {
        cache.mapApiPromise[localeKey] = new Promise(resolve => {
            const googleKey = apiKey || defaultGoogleKey;
            const callback = `vimTireInstallGoogleMapApiCallback_${language}_${country}`;
            const apiUrl = `https://maps.googleapis.com/maps/api/js?key=${googleKey}&channel=2&callback=${callback}&language=${language}&region=${country}`;
            const script = document.createElement('script');

            script.src = apiUrl; // dynamic scripts are async
            window[callback] = () => {
                resolve(google.maps);
                // destroy callback on window
                delete window[callback];
            };
            (document.getElementsByTagName('head')[0] || document.getRootNode()).appendChild(script);
        });
    }

    // always return the cached promise
    return cache.mapApiPromise[localeKey];
};

/**
 * Returns a new google.maps.Map instance
 * @param {HTMLElement} node html node where the map will be injected
 * @param {*} [config = defaultConfig] map configurations
 * @returns {google.maps.Map} a new map instance added to the given node
 */
const initMap = (node, config = mapConfig) => new google.maps.Map(node, config);

// map keyed marker lists
const markerManager = new Map();
/**
 * Returns a list of HTMLMarker instances that live on the map
 * @param {*} map
 * @returns
 */
const getMarkers = map => markerManager.get(map) || markerManager.set(map, []).get(map);
/**
 * @param {google.maps.Map} map instance
 * @param {*} config
 * @param {*} config.center
 * @param {string} config.center.accessibilityText title of center marker
 * @param {string|number} config.center.latitude lat of center marker
 * @param {string|number} config.center.longitude lng of center marker
 */
const addCenterMarker = (map, config) => {
    const markers = getMarkers(map);
    const {
        center: {
            latitude: lat,
            longitude: lng,
            accessibilityText
        } = {}
    } = config;
    const center = {
        lat: parseFloat(lat) || 0,
        lng: parseFloat(lng) || 0
    };
    const marker = new google.maps.Marker({
        position: center,
        map,
        title: accessibilityText
    });

    markers.push(marker);

    return marker;
};

const showInfoView = (map, mapIndex, dataElement) => {
    const markers = getMarkers(map);
    const marker = markers.length && markers[0];

    if(marker) {
        if(dataElement) {
            // cache the info element for future clicks
            infoElementCache[mapIndex] = dataElement;
        }
        if (infoWindow) {
            infoWindow.close();
        }
        infoWindow = new google.maps.InfoWindow({
            content: infoElementCache[mapIndex],
            zIndex: 3,
            maxWidth: 345,
            pixelOffset: new google.maps.Size(0,350)
        });
        infoWindow.open({
            anchor: marker,
            map
        });
    }
};

const MapSymbols = {
    center: (map, data) => addCenterMarker(map, data)
};
const addMarker = (map, data) => {
    if (!(data && map)) {
        return;
    }
    // add center point and circle
    if (MapSymbols[data.type]) {
        return MapSymbols[data.type](map, data);
    }

    const coord = {
        lat: parseFloat(data.latitude) || 0,
        lng: parseFloat(data.longitude) || 0
    };

    class HTMLMarker extends google.maps.OverlayView {
        constructor() {
            super();
            this.setMap(map);
        }
        draw() {
            // position and center marker
            const pos = this.getProjection().fromLatLngToDivPixel(coord);

            if (pos && data.el) {
                const { offsetWidth: w = 0, offsetHeight: h = 0 } = data.el;
                const xPos = pos.x - (w / 2) || pos.x;
                const yPos = pos.y - h || pos.y;

                assign(data.el.style, { left: `${xPos}px`, top: `${yPos}px` });
            }
        }
        getPosition() {
            return coord;
        }
        onAdd() {
            if (data.el) {
                assign(data.el.style, { borderStyle: 'none', borderWidth: 0, position: 'absolute' });
                this.getPanes().floatPane.appendChild(data.el);
            }
        }
        onRemove() {
            if (data.el) {
                data.el.remove();
                delete data.el;
            }
            this.setMap(null);
        }
        update() {
            const zoom = map.getZoom();

            this.draw();
            map.panBy(0, 0);
            map.setZoom(zoom);
        }
    };

    const htmlMarker = new HTMLMarker()

    getMarkers(map).push(htmlMarker);

    return htmlMarker;
};
/**
 * Removes all markers added with addMarker
 * @param {google.maps.Map} map the map to remove markers from.
 */
const removeMarkers = map => {
    const markers = getMarkers(map);

    markers.forEach(m => m.setMap(null));
    markers.length = 0;
};

// MAP BOUNDS
const countryBounds = {
    "world": "85,-180,-85,180",
    "at": "46.37,9.53,49.02,17.16",
    "au": "-55.32,72.25,-9.09,168.22",
    "be": "49.5,2.39,51.55,6.41",
    "ca": "41.68,-141,83.34,-52.32",
    "ch": "45.82,5.96,47.81,10.49",
    "cn": "8.84,73.5,53.56,134.78",
    "cz": "48.55,12.09,51.06,18.86",
    "de": "47.27,5.87,55.1,15.04",
    "dk": "54.45,7.72,57.95,15.55",
    "es": "27.43,-18.39,43.99,4.59",
    "fi": "59.45,19.08,70.09,31.59",
    "fr": "41.26,-5.45,51.27,9.87",
    "gb": "49.67,-14.02,61.06,2.09",
    "gr": "34.7,19.25,41.75,29.73",
    "hk": "22.12,114,22.44,114.32",
    "hu": "45.74,16.11,48.59,22.9",
    "id": "-11.21,94.77,6.27,141.02",
    "ie": "51.22,-11.01,55.64,-5.66",
    "il": "29.45,34.27,33.34,35.9",
    "in": "6.55,68.11,35.67,97.4",
    "it": "35.29,6.63,47.09,18.78",
    "jp": "20.21,122.71,45.71,154.21",
    "my": "-5.11,105.35,9.89,120.35",
    "nl": "50.73,1.92,53.73,7.23",
    "no": "57.76,4.09,71.38,31.76",
    "nz": "-52.82,-179.06,-29.03,179.36",
    "ph": "4.22,114.1,21.32,126.81",
    "pl": "49,14.12,55.03,24.15",
    "pr": "17.93,-67.27,18.52,-65.59",
    "pt": "29.83,-31.56,42.15,-6.19",
    "ru": "41.19,19.64,82.06,180",
    "se": "55.13,10.59,69.06,24.18",
    "sg": "1.13,103.69,1.45,104.01",
    "th": "5.61,97.34,20.46,105.64",
    "tw": "10.37,114.36,26.44,122.3",
    "us": "32.27,-116.88,44.61,-75.4",
    "vn": "8.18,102.14,23.39,114.33",
    "za": "-47.18,16.33,-22.13,38.29"
}
const world = 'world';
const extendBoundsToCountry = (bounds, config) => {
    const { country = world } = config;
    const toPt = (lat, lng) => ({ lat: parseFloat(lat), lng: parseFloat(lng) });
    const [ swlat, swlng, nelat, nelng ] = (countryBounds[country.toLowerCase()] || countryBounds[world] ).split(',').map(n => Number(n));

    bounds.extend(toPt(swlat, swlng));
    bounds.extend(toPt(nelat, nelng));
}
const extendBoundsToCircle = (bounds, config) => {
    const { center: { latitude: lat, longitude: lng }, radius = 5 } = config;

    const circle = new google.maps.Circle({
        center: { lat: parseFloat(lat), lng: parseFloat(lng) },
        radius: parseInt(radius) * 1609.34
    });
    bounds.union(circle.getBounds());
}
/**
 * Sets map bounds to markers on map instance. Uses country code to set bounds to
 * the country provided in the event there are no markers. If the bounds are not
 * available for the provided country code the US is used as a fallback.
 * @param {google.maps.Map} map instance
 * @param {*} config
 * @param {*} config.center
 * @param {string} config.center.accessibilityText title of center marker
 * @param {string|number} config.center.latitude lat of center marker
 * @param {string|number} config.center.longitude lng of center marker
 * @param {string|number} config.radius radius of search
 * @param {string} config.country case insensitive two digit country code
 */
const setBounds = (map, config = {}) => {
    const bounds = new google.maps.LatLngBounds();
    const markers = getMarkers(map);
    const {
        center: {
            latitude:lat,
            longitude:lng
        } = {}
    } = config

    if (!(lat || lng)) {
        // No center, means no markers! Show country bounds
        extendBoundsToCountry(bounds, config);
    } else if (markers.length < 2) {
        // Radius around the center marker
        extendBoundsToCircle(bounds, config);
    } else {
        // bounds of all map markers
        markers.forEach(m => {
            if (m.getBounds) {
                bounds.union(m.getBounds());
            } else if (m.getPosition) {
                bounds.extend(m.getPosition());
            }
        });
    }

    map.fitBounds(bounds);
    map.setZoom(map.getZoom());
};

module.exports = {
    addMarker,
    initMap,
    loadMapsApi,
    removeMarkers,
    setBounds,
    showInfoView
};
