const appendCustomTracking = (action, customTag) => {
    const trackingList = action && action.trackingList || [];

    if(trackingList && Array.isArray(trackingList)) {
        trackingList.forEach(tracking => {
            if(tracking) {
                Object.assign(tracking.eventProperty, customTag);
            }
        });
    }

    return action;
}

module.exports = {
    appendCustomTracking
}
