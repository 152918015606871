const { get } = require('@ebay/retriever');
const { appendCustomTracking } = require('../../../utils/customEventTag');

module.exports = class {
    onCreate(input) {
        const model = input.model || {};
        const calendarInput = get(model, 'VAS_APPOINTMENT_SCHEDULE.calendar', {});
        const defaultSelectedDate = get(input, 'selectedDate.0', '') || calendarInput.defaultSelectedDate;
        let defaultSelectedDateArray = null;
        if (defaultSelectedDate) {
            defaultSelectedDateArray = [defaultSelectedDate];
        }
        this.slotEntries = get(input, 'model.VAS_APPOINTMENT_SCHEDULE.time.entries', []);
        this.isoDateStringMap = calendarInput.additionalParamKeyValues || {};

        const slotDetails = this.getSlotDetails(defaultSelectedDate, true, input) || {};

        this.state = {
            pressed: [],
            selectedSlot: input.selectedSlot || slotDetails.selectedSlotParamValue || '',
            selectedDate: input.selectedDate || defaultSelectedDateArray,
            vasSelection: slotDetails.vasSelection,
            enableSlots: slotDetails.enabledSlots || [],
            isBusy: false,
            mapLoaded: false,
            loading: false
        };
    }

    onInput(input) {
        this.state.loading = input.showLoading || false;
    }

    onDateSelected(eventData) {
        this.state.mapLoaded = true;
        if (eventData && eventData.iso) {
            const slotDetails = this.getSlotDetails(eventData.iso, false) || {};
            this.state.selectedDate = [eventData.iso];
            this.state.enableSlots = slotDetails.enabledSlots || [];
            this.state.selectedSlot = slotDetails.selectedSlotParamValue || '';
            this.state.vasSelection = slotDetails.vasSelection;
        }
    }

    getSlotDetails(selectedDate, onload, input) {        
        const enableSlots = [];
        let selectedSlotParamValue = '';
        let vasSelection = null;
        let valueUpdated = false;
        
        this.slotEntries.forEach((entry) => {
            const dates = get(entry, "filter.values", []);
            if (dates.includes(selectedDate)) {
                enableSlots.push(get(entry, "paramValue", ''))
                const isUpdateRequired = onload ? ( ((input && input.selectedSlot) === entry.paramValue) || entry.selected) : !valueUpdated;
                if (isUpdateRequired) {
                    selectedSlotParamValue = entry.paramValue;
                    if(entry.additionalParamKeyValues) {
                        vasSelection = entry.additionalParamKeyValues;
                    }
                    valueUpdated = true;
                }
            }
        });

        return {
            enabledSlots : enableSlots,
            selectedSlotParamValue: selectedSlotParamValue,
            vasSelection: vasSelection
        };
    }

    timeSlot({selectedSlot, vasSelection}) {
        this.state.mapLoaded = true;
        this.state.selectedSlot = selectedSlot;
        this.state.vasSelection = vasSelection;
    }

    handleClick(action) {
        this.state.isBusy = true;
        const selectedParam = this.state.vasSelection || {};
        const selectedDate = this.state.selectedDate || [];
        let isoDateString = '';

        if(Array.isArray(selectedDate) && selectedDate.length) {
            isoDateString = this.isoDateStringMap[this.state.selectedDate[0]];
            Object.assign(selectedParam, {
                date: isoDateString
            });
        }

        this.emit('click', appendCustomTracking(action, {
            'VAS_FE_SELECTED_APPT_SLOTS': `${isoDateString},${selectedParam.slot}`
        }), selectedParam, selectedDate, this.state.selectedSlot);
    }

    onProviderClicked(provider) {
        const providerTrackingList = get(provider, 'text.action.trackingList', []);
        this.emit('publish-tracking', providerTrackingList);
    }
};
