
$ const { get } = require("@ebay/retriever");

$ {
    const slotModel = get(input, 'model', {});
    const slotEntries = get(slotModel, 'entries', []);
    const heading = get(slotModel, 'heading.textSpans[0].text', '');
    const enableSlots = get(input, 'enableSlots', []);
    const selectedSlot = get(input, 'selectedSlot', '');
}

<if(!slotModel.isEmpty && slotEntries.length > 0)>
    <div class="appointment__date-time">
        <fieldset>
            <legend class="clipped">${heading}</legend>
            <div class="appointment__date-time-slots">
                <for|entry,idx| of=slotEntries>
                    $ {
                        const entryLabel = get(entry, 'label', {});
                        const entrySecondaryLabels = get(entry, 'secondaryLabels', []);
                        let disabledState = !enableSlots.includes(entry.paramValue);
                    }
                    
                    <div class=["field", disabledState && "field-disable"]>
                        $ const checkedValue = (selectedSlot && selectedSlot === entry.paramValue) ? true : false; 
                        <ebay-radio
                            class="field__control"
                            id=`group-radio-${entry.paramKey}-${entry.paramValue}`
                            value=entry.paramValue
                            name=entry.paramKey
                            disabled=disabledState
                            checked=checkedValue
                            on-change("onRadioSelected", entry)
                            />
                            
                        <label class="field__label field__label--end"
                            for=`group-radio-${entry.paramKey}-${entry.paramValue}`>
                                <if(!entryLabel.__isEmpty)>
                                    <div class="appointment__date-time-label">
                                        <ux-textualdisplay model=entryLabel />
                                    </div>
                                </if>
                                <if(Array.isArray(entrySecondaryLabels) && entrySecondaryLabels.length > 0)>
                                    <for|entrySecondaryLabel| of=(entrySecondaryLabels || [])>
                                        <div class="appointment__date-time-seclabel">
                                            <ux-textualdisplay model=entrySecondaryLabel/>
                                        </div>
                                    </for>
                                </if>
                        </label>
                    </div>
                </for>
                <div hidden='true' aria-hidden='true'>
                    <svg>
                        <symbol#icon-radio-unchecked-18 viewBox='0 0 18 18'>
                            <path d='M9 18A9 9 0 119 0a9 9 0 010 18zM9 1a8 8 0 100 16A8 8 0 009 1z'/>
                        </symbol>
                        <symbol#icon-radio-checked-18 viewBox='0 0 18 18'>
                            <path d='M9 0a9 9 0 110 18A9 9 0 019 0zm0 1a8 8 0 100 16A8 8 0 009 1zm0 3a5 5 0 110 10A5 5 0 019 4z'/>
                        </symbol>
                        <symbol viewBox="0 0 24 24" id="icon-chevron-right-24">
                            <path d="M17.707 11.293a1 1 0 0 1 .22.33l-.22-.33Zm-.001-.001-7.999-8a1 1 0 0 0-1.414 1.415L15.586 12l-7.293 7.293a1 1 0 1 0 1.414 1.414l8-8a.999.999 0 0 0 .22-1.083"></path>
                        </symbol>
                        <symbol viewBox="0 0 24 24" id="icon-chevron-left-24">
                            <path d="m6.293 11.292 8-8a1 1 0 1 1 1.414 1.415L8.414 12l7.293 7.293a1 1 0 1 1-1.414 1.414l-8-8a.996.996 0 0 1 0-1.415Z"></path>
                        </symbol>
                    </svg>
                </div>
            </div>
        </fieldset>
    </div>
</if>

