$ const { get } = require('@ebay/retriever');

$ {
    const {
        model: {
            message
        } = {},
        type
    } = input;
}
<if(message)>
    <section aria-labelledby="pi-error-page-clipped-text" class="error-screen" role="region">
        <div class="clipped" id="pi-error-page-clipped-text">
            ${get(message, "additionalText.0.accessibilityText")}
        </div>
        <div class="error-screen__content">
            <ebay-attention-64-icon />
            <h3>
                <ux-textualdisplay model=get(message, "title", {}) />
            </h3>
            <p>
                <for|additionalText| of=(get(message, "additionalText", []))>
                    <ux-textualdisplay model=additionalText />
                </for>
            </p>
        </div>
    </section>    
</if>