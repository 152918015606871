module.exports = class {
    onCreate(input) {
        const radius = input.value;
        this.state = {
            error: !radius,
            value: radius
        };
    }

    handleValueEvent(eventName, { selected, value }) {
        const selectedValue = value || selected[0];

        this.state.error = !selectedValue || selectedValue === 'null';
        this.state.value = selectedValue;

        if(selectedValue && selectedValue !== 'null') {
            this.emit('value-change', {
                'name': eventName,
                'radius': selectedValue
            });
        }

        this.emit('disable-action', { invalidRadius: this.state.error });
    }
}
