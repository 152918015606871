// Provides helper function to process VAS_RADIUS_SEARCH
// @usage extractPostCodeRadiusValues(radiusSearchForm)
const { get } = require('@ebay/retriever');

const extractPostCodeRadiusValues = (radiusSearchForm) => {
    const values = {};
    if (radiusSearchForm && Array.isArray(radiusSearchForm) && radiusSearchForm.length > 0) {
        radiusSearchForm.forEach(element => {
            if (element._type === "Group") {
                const radiusList = get(element, 'entries', []);
                values.radius = radiusList.find(({selected}) => selected === true).paramValue;
            } else if (element._type === "TextualEntry") {
                values.postalCode = element.paramValue;
            }
        });
    }
    return values;
}

module.exports = {
    extractPostCodeRadiusValues
};
