$ const { get } = require('@ebay/retriever');
$ const sectionModel = get(input, 'model', {});
<div class="section">
    <span class="section__title">
        <ux-textualdisplay model=sectionModel.title />
    </span>
    <div class="section__dataitems">
        <for|entry, index| of=(get(sectionModel, 'dataItems', []))>
            <ux-textualdisplay model=entry />
        </for>
    </div>
</div>
