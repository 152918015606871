$ const { get } = require('@ebay/retriever');

$ const {
    model: {
        title,
        image,
        displayPriceMessage,
        quantity,
        __vas
    } = {}
} = input;

$ const belowSection = get(__vas, 'detailSection', {});

<div class="item-card">
    <div class="item-card__image">
        <if(image && image.URL)>
            <img src=image.URL loading="lazy" alt=image.title />
        </if>
        <else>
            <ebay-gallery-24-icon />
            <!-- Debug :- SVG not loading through the ebay UI-->
            <div hidden aria-hidden="true">
                <svg viewBox="0 0 24 24" id="icon-photo-gallery">
                    <path d="M16.5 8a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z"></path>
                    <path fill-rule="evenodd" d="M8 1a3 3 0 0 0-3 3v12a3 3 0 0 0 3 3h12a3 3 0 0 0 3-3V4a3 3 0 0 0-3-3H8ZM7 16v-.638l3.984-4.781 3.235 4.044a1 1 0 0 0 1.488.082L18 12.414l3 3V16a1 1 0 0 1-1 1H8a1 1 0 0 1-1-1Zm11.707-5.707L21 12.586V4a1 1 0 0 0-1-1H8a1 1 0 0 0-1 1v8.238l3.232-3.878a1 1 0 0 1 1.549.015l3.302 4.128 2.21-2.21a1 1 0 0 1 1.414 0Z" clip-rule="evenodd"></path>
                    <path d="M3 9a1 1 0 0 0-2 0v11a3 3 0 0 0 3 3h11a1 1 0 1 0 0-2H4a1 1 0 0 1-1-1V9Z"></path>
                </svg>
            </div>
        </else>
    </div>
    <div class="item-card__right-content">
        <h4 class="item-card__title">
            <ux-textualdisplay model=title />
        </h4>
        <p class="item-card__secondary-text">
            <ux-textualdisplay model=displayPriceMessage />
            <ux-textualdisplay model=quantity />
        </p>
        <div class="item-card__below-section">
            <section model=belowSection/>
        </div>
    </div>
</div>
