
import wrench from '<ebay-wrench-24-icon>';
import store from '<ebay-store-24-icon>';
import calendar from '<ebay-calendar-24-icon>';

$ const {
    icon,
    image,
    index
} = input.model || {};

$ const iconMap = {
    WRENCH: wrench,
    SHOP: store,
    CALENDAR: calendar
};

<if(icon)>
    <div class="card-tile__card-left-icon">
        <${iconMap[icon.name]}/>
    </div>
</if>
<else-if(image)>
    <div class="card-tile__card-left-image">
        <img src=image.URL alt=image.title />
    </div>
</else-if>
<else>
    <div class="card-tile__card-left-index">
        <span>
            ${index + 1}
        </span>
    </div>
</else>