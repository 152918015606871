$ const { get } = require('@ebay/retriever');
$ const {
    model: {
        title,
        subTitles,
        sections
    } = {}
} = input;

<div class="how-works">
    <if(title)>
        <div class="how-works__header">
            <div class="how-works__title">
                <ux-textualdisplay model=title />
            </div>
            <if(subTitles)>
                <div class="how-works__learn-more" on-click('emit', 'learn-more-click', subTitles && subTitles[0].action)>
                    <ux-textualdisplay model=(subTitles && subTitles[0])/>
                </div>
            </if>
        </div>
    </if>
    <ul class="how-works__scroll">
        <for|entry, index| of=(get(sections, '0.dataItems', []))>
            <if (index % 2 === 0)>
                <li class="how-works__question">
                    <ux-textualdisplay model=entry />
                </li>
            </if>
            <else>
                <li class="how-works__answer">
                    <ux-textualdisplay model=entry />
                </li>
            </else>
        </for>
    </ul>
</div>
