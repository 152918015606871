$ const { get } = require('@ebay/retriever');

$ const {
    sections
} = input.model || {};

<if(sections)>
    <for|section| of=sections>
        <if(input.isMobile)>
            <h3 class="parts-scheduling__faq-title"><ux-textualdisplay model=section.title /></h3>
            <ul class="parts-scheduling__faq-deatils">
                <for|faq| of=section.dataItems>
                    <for|content, index| of=faq.labels>
                        <li>
                            <ux-textualdisplay model=content />
                            $ const value = faq.values && faq.values[index];
                            <if(value)>
                                <p>
                                    <ux-textualdisplay model=value />
                                </p>
                            </if>
                        </li>
                    </for>
                </for>
            </ul>            
        </if>
        <else>
            <ebay-details class="parts-scheduling__faq" size="regular" open=false as="p">
                <@summary>
                    ${get(section, 'title.textSpans.0.text', '')}
                </@summary>
                <ul class="parts-scheduling__faq-deatils">
                    <for|faq| of=section.dataItems>
                        <for|content, index| of=faq.labels>
                            <li>
                                <ux-textualdisplay model=content />
                                $ const value = faq.values && faq.values[index];
                                <if(value)>
                                    <p>
                                        <ux-textualdisplay model=value />
                                    </p>
                                </if>
                            </li>
                        </for>
                    </for>
                </ul>
            </ebay-details> 
        </else>
    </for>
</if>
  
