const { get } = require('@ebay/retriever');

module.exports = class {
    onCreate(input) {
        const model = input.model || {};
        const errorResponse = get(model, 'VAS_ERROR', {});
        const vasPersonalData = get(model, 'VAS_PERSONAL_DATA', {});
        const personalData = this.getPersonalData(vasPersonalData);
        const appointmentErrorCase = !errorResponse.__isEmpty;
        const disableCta = this.isDisableCTA(personalData.validationResult, appointmentErrorCase);
        this.personalDataFields = Object.keys(personalData.parsonalDataValue) || [];
        this.addtionalParamKey = personalData.addtionalParamKey || {};
        this.state = {
            validationResult: personalData.validationResult,
            ...personalData.validationResult,
            ...personalData.parsonalDataValue,
            appointmentErrorCase,
            disableCta,
            loading: false,
        };
        this.isMobile = get(input, 'global.isMobile', false);
    }

    onInput(input) {
        this.state.loading = input.showLoading || false;
    }

    isDisableCTA (personalDataValidationResult, appointmentErrorCase) {
        // If there's an appointment error case, immediately return true to disable the CTA
        if (appointmentErrorCase) {
            return true;
        }

        // Iterate over the keys of the personalDataValidationResult object
        for (const key in personalDataValidationResult) {
            // Check if the key ends with '_result' and the value is false
            if (key.endsWith('_result') && personalDataValidationResult[key] === false) {
                // If any validation result is false, return false (CTA should be disabled)
                return true;
            }
        }

        // If none of the validation results are false, return true (CTA should not be disabled)
        return false;
    }

    getPersonalData(vasPersonalData) {
        const dataItems = get(vasPersonalData, 'dataItems' , []);
        const validationResult = {};
        const parsonalDataValue = {};
        const addtionalParamKey = {};
        
        if (dataItems.length > 0) {
            dataItems.forEach(dataItem => {
                const entries = get(dataItem, 'entries',[]);
                const validations = get(dataItem, 'validations',[]);
                for(let entryIdx=0; entryIdx < entries.length; entryIdx++){
                    const entry = entries[entryIdx];
                    Object.assign(addtionalParamKey, entry.additionalParamKeyValues);
                    parsonalDataValue[entry.paramKey] = entry.paramValue;
                    validationResult[`${entry.paramKey}_result`] = this.getValidationResult(entry.paramValue,validations[entryIdx]);
                    validationResult[`${entry.paramKey}_showError`] = false;
                }
            });
        }
        return {
            validationResult,
            parsonalDataValue,
            addtionalParamKey
        };
    }
    
    getValidationResult(paramValue, validation) {
        const textPatterns = get(validation, 'textPatterns', []);
        for(let i=0;i<textPatterns.length;i++){
            const result = new RegExp(textPatterns[i]).test(paramValue);
            if(!result) {
                return false;
            }
        }
        return true;
    }

    handleInputChange(paramKey, validation, evt){
        const inputValue = evt.value;
        const currValidationResult = this.getValidationResult(inputValue,validation);
        const validationResult = this.state.validationResult;
        validationResult[`${paramKey}_result`] = currValidationResult;
        validationResult[`${paramKey}_showError`] = true;
        this.setState ({
            validationResult,
            ...validationResult,
            [paramKey]: inputValue,
            disableCta: this.isDisableCTA(validationResult, this.state.appointmentErrorCase)
        })
    }

    handleClick(action) {
        this.state.loading = true;
        const selectedParam = this.addtionalParamKey || {};

        if (this.personalDataFields.length) {
            this.personalDataFields.forEach((field) => {
                selectedParam[field] = this.state[field];
            });
        }
        
        this.emit('click', action, selectedParam);
    }

    handleCard(action) {
        this.state.loading = true;
        this.emit('click-card', action);
    }

    onProviderClicked(provider) {
        const providerTrackingList = get(provider, 'action.trackingList', []);
        this.emit('publish-tracking', providerTrackingList);
    }
};
