<macro|{ skeletonInput}| name="renderSkeleton">
    <div>
        <if(skeletonInput && skeletonInput.count > 0)>
            <for|i| from=1 to=skeletonInput.count step=1>
                <ebay-skeleton>
                    <if(skeletonInput.type == 'composite')>
                        <ebay-skeleton-avatar as="span"/>
                        <ebay-skeleton-text multiline="true" as="span" class="demo-composite-text"/>
                    </if>
                    <else-if(skeletonInput.type == 'textbox')>
                        <ebay-skeleton-textbox />
                    </else-if>
                    <else-if(skeletonInput.type == 'image')>
                        <ebay-skeleton-image/>
                    </else-if>
                </ebay-skeleton>
            </for>
        </if>
    </div>
</macro>

<div class="screen-loading">
    <div class="screen-loading__content">
        <if(input.top && input.top.count > 0)>
            <div class="screen-loading__content-top">
                <renderSkeleton skeletonInput=input.top />
            </div>
        </if>
        <div class="screen-loading__content-main">
             <if(input.left && input.left.count > 0)>
                <div class="screen-loading__content-left">
                    <renderSkeleton skeletonInput=input.left />       
                </div>
            </if>
            <if(input.right && input.right.count > 0)>
                <div class="screen-loading__content-right">
                    <renderSkeleton skeletonInput=input.right />        
                </div>
            </if>
        </div>
    </div>
    <if(input.footer && input.footer.count > 0)>
        <div class="screen-loading__content-footer">
            <renderSkeleton skeletonInput=input.footer />         
        </div>
    </if>       
</div>