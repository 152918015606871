$ const {
    sections
} = input.model || {};

<if(sections)>
    <for|section| of=sections>
        <div class="parts-scheduling__help">
            <h4>
                <ux-textualdisplay model=(section && section.title) />
            </h4>
            <div class="parts-scheduling__help-item">
                $ const dataItems = section && section.dataItems;
                <if(dataItems)>
                    <for|helpItem| of=dataItems>
                        <ul>
                            <for|content| of=helpItem.content>
                                <li>
                                    <ux-textualdisplay model=content />
                                </li>
                            </for>
                        </ul>
                    </for>
                </if>
            </div>
        </div>
    </for>
</if>