<div hidden>
  <svg>
    <symbol viewBox="0 0 24 24" id="icon-attention-filled-24">
      <path fill="#e62048" fill-rule="evenodd" d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12C23.994 5.375 18.625.006 12 0zm0 18a1 1 0 110-2 1 1 0 010 2zm1-5V7a1 1 0 00-2 0v6a1 1 0 002 0z" />
    </symbol>
    <symbol viewbox="0 0 18 18" id="icon-close-18">
      <path fill="#111820" d="M10.41 9l7.294-7.287A1.004 1.004 0 0016.285.294L9 7.59 1.715.294a1.002 1.002 0 10-1.42 1.42l7.296 7.285-7.295 7.286a1 1 0 000 1.42 1 1 0 001.419 0L9 10.407l7.285 7.296a1 1 0 001.42 0 1 1 0 000-1.419l-7.296-7.286z"></path>
    </symbol>
    <symbol id="icon-spinner-24" viewBox="0 0 24 24" fill="none">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M12 2C10.0222 2 8.08879 2.58649 6.4443 3.6853C4.79981 4.78412 3.51809 6.3459 2.76121 8.17317C2.00433 10.0004 1.8063 12.0111 2.19215 13.9509C2.578 15.8907 3.53041 17.6725 4.92894 19.0711C6.32746 20.4696 8.10929 21.422 10.0491 21.8079C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C22 11.4477 22.4477 11 23 11C23.5523 11 24 11.4477 24 12C24 14.3734 23.2962 16.6935 21.9776 18.6668C20.6591 20.6402 18.7849 22.1783 16.5922 23.0866C14.3995 23.9948 11.9867 24.2324 9.65892 23.7694C7.33115 23.3064 5.19295 22.1635 3.51472 20.4853C1.83649 18.8071 0.693605 16.6689 0.230582 14.3411C-0.232441 12.0133 0.00519943 9.60051 0.913451 7.4078C1.8217 5.21509 3.35977 3.34094 5.33316 2.02236C7.30655 0.703788 9.62663 0 12 0C12.5523 0 13 0.447715 13 1C13 1.55228 12.5523 2 12 2Z" fill="var(--color-spinner-icon-foreground, #E5E5E5)"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M14.1805 1.17194C14.3381 0.642616 14.895 0.341274 15.4243 0.498872C17.3476 1.07149 19.0965 2.11729 20.5111 3.54055C21.9257 4.96382 22.9609 6.71912 23.5217 8.64584C23.6761 9.17611 23.3714 9.73112 22.8411 9.88549C22.3108 10.0399 21.7558 9.73512 21.6015 9.20485C21.134 7.59925 20.2715 6.13651 19.0926 4.95045C17.9138 3.76439 16.4563 2.8929 14.8536 2.41572C14.3243 2.25812 14.0229 1.70126 14.1805 1.17194Z" fill="var(--color-spinner-icon-background, #3665F3)"/>
    </symbol>
    <symbol viewBox="0 0 16 16" id="icon-the-ebay-vault-16">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11 8c0 .463-.105.902-.292 1.293l.5.5a1 1 0 0 1-1.415 1.414l-.5-.5C8.902 10.896 8.463 11 8 11a2.99 2.99 0 0 1-1.293-.292l-.5.5a1 1 0 0 1-1.414-1.415l.5-.5A2.988 2.988 0 0 1 5 8c0-.463.105-.902.292-1.293l-.5-.5a1 1 0 0 1 1.415-1.414l.5.5C7.098 5.104 7.537 5 8 5c.463 0 .902.105 1.293.292l.5-.5a1 1 0 0 1 1.414 1.415l-.5.5c.188.391.293.83.293 1.293ZM9 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1 4a3 3 0 0 1 3-3h8a3 3 0 0 1 3 3v8a3.001 3.001 0 0 1-2 2.83V15a1 1 0 0 1-1 1h-1a1 1 0 0 1-1-1H6a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1v-.17A3.001 3.001 0 0 1 1 12V4Zm3-1h8a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1Z"
      />
    </symbol>
    <symbol viewBox="0 0 24 24" id="icon-the-ebay-vault-24">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M17 11c0 1.02-.305 1.967-.828 2.757l1.535 1.536a1 1 0 0 1-1.414 1.414l-1.536-1.535A4.977 4.977 0 0 1 12 16a4.977 4.977 0 0 1-2.757-.828l-1.536 1.535a1 1 0 0 1-1.414-1.414l1.535-1.536A4.977 4.977 0 0 1 7 11c0-1.02.305-1.967.828-2.757L6.293 6.707a1 1 0 0 1 1.414-1.414l1.536 1.535A4.977 4.977 0 0 1 12 6c1.02 0 1.967.305 2.757.828l1.536-1.535a1 1 0 1 1 1.414 1.414l-1.535 1.536C16.695 9.033 17 9.98 17 11Zm-2 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"></path>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M1 4a4 4 0 0 1 4-4h14a4 4 0 0 1 4 4v14a4.002 4.002 0 0 1-3 3.874V23a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1v-1H8v1a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-1.126A4.002 4.002 0 0 1 1 18V4Zm4-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2Z"></path>
    </symbol>
    <symbol viewBox="0 0 24 24" id="icon-top-service-24">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.534 7.625a7 7 0 1 1 0 8.749 1 1 0 1 0-1.56 1.251 9 9 0 1 0 0-11.251 1 1 0 0 0 1.56 1.251ZM16 9a1 1 0 1 0-2 0v3a1 1 0 0 0 .293.707l2 2a1 1 0 0 0 1.414-1.414L16 11.586V9ZM0 10a1 1 0 0 1 1-1h5a1 1 0 0 1 0 2H1a1 1 0 0 1-1-1Zm3 3a1 1 0 1 0 0 2h3a1 1 0 1 0 0-2H3Z"
      />
    </symbol>
    <symbol viewBox="0 0 24 24" id="icon-lock-24">
      <path d="M12 13a1 1 0 0 1 1 1v4a1 1 0 1 1-2 0v-4a1 1 0 0 1 1-1Z" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6 9V7a6 6 0 1 1 12 0v2a3 3 0 0 1 3 3v8a3 3 0 0 1-3 3H6a3 3 0 0 1-3-3v-8a3 3 0 0 1 3-3Zm2-2a4 4 0 1 1 8 0v2H8V7Zm-2 4a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-8a1 1 0 0 0-1-1H6Z"
      />
    </symbol>
    <symbol viewBox="0 0 24 24" id="icon-lock-24">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.001 4.999a2 2 0 0 1 2-2h6.167a1 1 0 0 1 .714.3l8.831 8.99a1 1 0 0 1-.006 1.406l-7.004 7.014a1 1 0 0 1-1.41.007L3.3 11.875a1 1 0 0 1-.299-.713V5Zm2-3.999a4 4 0 0 0-4 3.999v6.163c0 .804.323 1.575.896 2.138l8.994 8.84a3.002 3.002 0 0 0 4.228-.019l7.004-7.014a2.998 2.998 0 0 0 .018-4.22l-8.832-8.989A3.002 3.002 0 0 0 11.17 1H5.001Zm2.503 8a1.5 1.5 0 1 0 .001-3.001 1.5 1.5 0 0 0 0 3.001Z"
			/>
    </symbol>
    <symbol viewBox="0 0 24 24" id="icon-authenticity-guarantee-24">
      <path 
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.067 6.647a3.756 3.756 0 0 1-.875 2.113A4.98 4.98 0 0 0 0 12a4.98 4.98 0 0 0 1.191 3.24 3.75 3.75 0 0 1 .876 2.113 4.98 4.98 0 0 0 1.448 3.132 4.98 4.98 0 0 0 3.133 1.449c.77.061 1.524.374 2.113.875A4.98 4.98 0 0 0 12 24a4.98 4.98 0 0 0 3.24-1.191 3.756 3.756 0 0 1 2.113-.875 4.98 4.98 0 0 0 3.133-1.449 4.98 4.98 0 0 0 1.448-3.133 3.76 3.76 0 0 1 .875-2.113A4.98 4.98 0 0 0 24 12a4.98 4.98 0 0 0-1.19-3.239 3.756 3.756 0 0 1-.876-2.113 4.98 4.98 0 0 0-1.448-3.133 4.98 4.98 0 0 0-3.133-1.449 3.756 3.756 0 0 1-2.114-.875A4.98 4.98 0 0 0 12 0a4.98 4.98 0 0 0-3.24 1.191 3.756 3.756 0 0 1-2.112.875 4.98 4.98 0 0 0-3.133 1.449 4.98 4.98 0 0 0-1.448 3.132ZM4.93 4.929a2.98 2.98 0 0 0-.87 1.878 5.756 5.756 0 0 1-1.346 3.25A2.98 2.98 0 0 0 2 12c0 .743.268 1.419.714 1.943a5.756 5.756 0 0 1 1.346 3.25 2.98 2.98 0 0 0 .87 1.878 2.98 2.98 0 0 0 1.878.869 5.76 5.76 0 0 1 3.25 1.346A2.98 2.98 0 0 0 12 22a2.98 2.98 0 0 0 1.943-.714 5.756 5.756 0 0 1 3.25-1.346 2.98 2.98 0 0 0 1.879-.869 2.98 2.98 0 0 0 .869-1.879 5.756 5.756 0 0 1 1.345-3.25A2.98 2.98 0 0 0 22 12a2.98 2.98 0 0 0-.714-1.943 5.756 5.756 0 0 1-1.346-3.25 2.98 2.98 0 0 0-.869-1.878 2.98 2.98 0 0 0-1.878-.869 5.756 5.756 0 0 1-3.25-1.346A2.98 2.98 0 0 0 12 2a2.98 2.98 0 0 0-1.943.714A5.756 5.756 0 0 1 6.808 4.06a2.98 2.98 0 0 0-1.878.869Zm11.781 5.106a1 1 0 0 0-1.414-1.414l-4.632 4.631-1.962-1.962a1 1 0 0 0-1.414 1.415l2.67 2.669a1 1 0 0 0 1.414 0l5.338-5.339Z"
      />
    </symbol>
    <symbol viewBox="0 0 24 24" id="icon-escrow-24">
      <path d="M12 13a1 1 0 0 1 1 1v4a1 1 0 1 1-2 0v-4a1 1 0 0 1 1-1Z" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6 9V7a6 6 0 1 1 12 0v2a3 3 0 0 1 3 3v8a3 3 0 0 1-3 3H6a3 3 0 0 1-3-3v-8a3 3 0 0 1 3-3Zm2-2a4 4 0 1 1 8 0v2H8V7Zm-2 4a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-8a1 1 0 0 0-1-1H6Z"
      />
    </symbol>
    <symbol viewBox="0 0 24 24" id="icon-selling-24">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.001 4.999a2 2 0 0 1 2-2h6.167a1 1 0 0 1 .714.3l8.831 8.99a1 1 0 0 1-.006 1.406l-7.004 7.014a1 1 0 0 1-1.41.007L3.3 11.875a1 1 0 0 1-.299-.713V5Zm2-3.999a4 4 0 0 0-4 3.999v6.163c0 .804.323 1.575.896 2.138l8.994 8.84a3.002 3.002 0 0 0 4.228-.019l7.004-7.014a2.998 2.998 0 0 0 .018-4.22l-8.832-8.989A3.002 3.002 0 0 0 11.17 1H5.001Zm2.503 8a1.5 1.5 0 1 0 .001-3.001 1.5 1.5 0 0 0 0 3.001Z"
      />
    </symbol>
    <symbol#icon-radio-unchecked-18 viewBox='0 0 18 18'>
      <path d='M9 18A9 9 0 119 0a9 9 0 010 18zM9 1a8 8 0 100 16A8 8 0 009 1z'/>
    </symbol>
    <symbol#icon-radio-checked-18 viewBox='0 0 18 18'>
        <path d='M9 0a9 9 0 110 18A9 9 0 019 0zm0 1a8 8 0 100 16A8 8 0 009 1zm0 3a5 5 0 110 10A5 5 0 019 4z'/>
    </symbol>
    <symbol width="45" height="16" viewBox="0 0 45 16" xmlns="http://www.w3.org/2000/svg" id="icon-psa-16">
      <path 
        d="M25.2 3.189c.028-.024.01-.07-.028-.066-2.527.187-3.883.44-5.464.962-1.605.551-2.903 1.406-2.903 1.914 0 .23.254.44.743.598.513.163.95.275 1.304.345l2.437.551c1.464.322 2.974.878 3.86 1.43.72.438 1.091 1.082 1.091 1.867 0 3.202-4.438 5.21-11.596 5.21-2.324 0-3.487-.415-3.487-1.27 0-.299.23-.76.72-1.475.372-.51.584-.691.955-.808 1.535-.486 1.346-.439 1.511-.439.09 0 .165.075.165.164 0 .135-.047.182-.254.345l-.447.327c-.029.023-.014.065.023.07.659.023 1.294.042 1.469.042 3.044 0 5.463-.439 7.506-1.359.908-.392 1.28-.69 1.28-.99 0-.298-.325-.555-1.163-.877-.767-.299-1.74-.58-2.348-.691l-3.836-.691c-1.717-.3-2.371-.855-2.371-2.054 0-.346.094-.902.277-1.592.396-1.43.72-1.845 1.906-2.512C19.035.784 22.15 0 25.101 0c.932 0 1.93.117 2.861.322.514.094.603.229.603.808 0 .667-.207 1.452-.513 1.797-.254.346-.372.416-1.44.785-.673.228-.955.392-2.07 1.13-.255.163-.396.228-.556.228-.188 0-.372-.158-.372-.322 0-.14.047-.229.278-.415L25.2 3.189Z"
        fill="#EB1C2D"
      />
      <path 
        d="M43.487 13.993h.076c.09 0 .16-.028.16-.098 0-.06-.047-.103-.146-.103a.368.368 0 0 0-.09.01v.19Zm-.004.387h-.16v-.68c.061-.014.15-.019.263-.019.132 0 .189.019.24.051a.188.188 0 0 1 .066.15c0 .075-.056.135-.14.159v.009c.065.023.103.075.126.168a.47.47 0 0 0 .052.173h-.174c-.019-.024-.033-.089-.056-.168-.015-.075-.057-.108-.142-.108h-.075v.266Zm-.424-.36c0 .304.226.547.537.547a.524.524 0 0 0 .522-.542c0-.303-.22-.55-.527-.55a.533.533 0 0 0-.532.546Zm1.238 0a.689.689 0 0 1-.706.692.695.695 0 0 1-.71-.691c0-.378.315-.682.71-.682.4 0 .706.304.706.682Zm-9.355-4.63h-5.045c-.024 0-.038-.024-.029-.043l2.485-4.79a.028.028 0 0 1 .052 0l2.555 4.79c.019.02.005.042-.019.042Zm.098-7.849a.117.117 0 0 0-.103-.06h-5.092a.123.123 0 0 0-.104.06L25.915 8.55a.156.156 0 0 0 .038.191c.593.533.918 1.242.918 2.05 0 1.037-.4 2.414-2.08 3.59-.048.033-.024.108.032.108h2.273a.117.117 0 0 0 .104-.061l1.134-2.213c.01-.014.024-.023.038-.023h8.099c.014 0 .028.01.038.023l1.204 2.218c.019.037.061.06.104.06h4.334a.041.041 0 0 0 .038-.06L35.04 1.542ZM11.905 6.607c0 .77-.362 1.382-1.303 1.382H4.47a.116.116 0 0 1-.118-.117v-3.04a.12.12 0 0 1 .118-.116h5.788c1.214 0 1.647.504 1.647 1.382v.509Zm3.869 2.208c.004-.023-.005-.042-.029-.051-1.091-.322-2.014-.957-2.014-2.47 0-.523.165-1.242.301-1.755.245-.883.49-1.448.89-1.905a.042.042 0 0 0 0-.056c-.725-.687-1.869-1.093-3.633-1.093H.45a.116.116 0 0 0-.118.117V14.38c0 .066.052.117.118.117h3.788a.116.116 0 0 0 .118-.117v-3.04c0-.064.051-.116.117-.116h7.869c1.986-.004 2.998-1.092 3.43-2.409Z"
        fill="#0050A2"
      />
    </symbol>
    <symbol viewBox="0 0 24 24" id="icon-clock-24">
      <path
        d="M23 12c0 6.075-4.925 11-11 11S1 18.075 1 12 5.925 1 12 1s11 4.925 11 11Zm-2 0a9 9 0 0 0-9-9 9 9 0 0 0-9 9 9 9 0 0 0 9 9 9 9 0 0 0 9-9ZM11 6a1 1 0 0 1 2 0v5.59l2.7 2.71a1 1 0 0 1-1.41 1.41l-3-3A.999.999 0 0 1 11 12V6Z"
      />
    </symbol>
    <symbol viewBox="0 0 24 24" id="icon-promotion-24">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.002 4a2 2 0 0 1 2-2h5.158a1 1 0 0 1 .714.3l6.845 6.985a1 1 0 0 1-.007 1.408l-1.877 1.874L12.302 5.9A3 3 0 0 0 10.16 5H6.002V4Zm16.123 8.108-2.198 2.195a2.998 2.998 0 0 1-.802 2.805l-6.019 6.012a3 3 0 0 1-4.219.02l-6.983-6.837a3 3 0 0 1-.901-2.144V9a4.002 4.002 0 0 1 3-3.874V4a4 4 0 0 1 4-4h5.157a3 3 0 0 1 2.142.9l6.846 6.985a3 3 0 0 1-.023 4.223ZM5.002 7a2 2 0 0 0-2 2v5.16a1 1 0 0 0 .301.714l6.984 6.838a1 1 0 0 0 1.406-.007l6.019-6.012a1 1 0 0 0 .007-1.408L10.874 7.3a1 1 0 0 0-.714-.3H5.002ZM7.5 13a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z"
      />
    </symbol>
    <symbol viewBox="0 0 24 24" id="icon-support-24">
      <path
        d="M20.613 2.21a1 1 0 0 0-1.205 1.596A3.991 3.991 0 0 1 21 7c0 1.27-.595 2.44-1.59 3.193a1 1 0 0 0 1.206 1.595A5.991 5.991 0 0 0 23 7a5.991 5.991 0 0 0-2.387-4.79ZM10 3C7.288 3 5.091 5.236 5.091 8c0 1.505.656 4.52 1.69 5.436l-3.594 1.652C1.819 16.058.998 17.69 1 19.438v2.69c0 .481.366.871.818.871h16.364c.451 0 .817-.39.817-.87v-2.69a5.194 5.194 0 0 0-1.098-3.3 5.357 5.357 0 0 0-1.047-.989l-3.657-1.695c1.047-.917 1.712-3.939 1.712-5.456 0-2.762-2.198-5-4.909-5Zm0 2c1.604 0 2.909 1.345 2.909 3 0 1.354-.623 3.52-1.03 3.95a2 2 0 0 0 .477 3.32l3.466 1.606c.192.152.365.324.509.501.443.562.681 1.283.668 2.061V21H3v-1.563c-.001-1.046.449-2.013 1.213-2.619l3.403-1.564a1.998 1.998 0 0 0 1.152-1.517 1.981 1.981 0 0 0-.645-1.783C7.706 11.508 7.091 9.348 7.091 8c0-1.654 1.305-3 2.909-3Z"
      />
      <path
        d="M16.294 4.291a1 1 0 0 1 1.415.003C18.54 5.13 19 5.957 19 7c0 1.044-.459 1.871-1.292 2.706a1 1 0 1 1-1.416-1.412C16.787 7.796 17 7.413 17 7c0-.413-.213-.797-.709-1.294a1 1 0 0 1 .003-1.415Z"
      />
    </symbol>
  </svg>
</div>