class {
    onCreate(input) {
        this.state = {
            disableButton: !input.postalCodeValue || !input.radiusValue,
            invalidPostalCode: !input.postalCodeValue,
            invalidRadius: !input.radiusValue
        }
    }

    disableAction(error) {
        if(error.invalidPostalCode !== undefined) {
            this.state.invalidPostalCode = error.invalidPostalCode;
        }

        if(error.invalidRadius !== undefined) {
            this.state.invalidRadius = error.invalidRadius;
        }

        this.state.disableButton = this.state.invalidPostalCode || this.state.invalidRadius;
    }
};

$ const { get } = require('@ebay/retriever');

$ const {
    model: {
        radius,
        postalCode,
        getInstallerList: {
            action
        } = {}
    } = {},
    postalCodeValue,
    radiusValue
} = input;

<div class="find-area">
    <radius
        model=radius
        value=radiusValue
        on-value-change('emit', 'value-change', action)
        on-disable-action('disableAction') />
    <postal-code
        model=postalCode
        value=postalCodeValue
        on-value-change('emit', 'value-change', action)
        on-disable-action('disableAction') />

    <if(action)>
        <ebay-icon-button
            class="find-area__cheveron"
            disabled=state.disableButton
            aria-label=action.accessibilityText
            on-click('emit', 'get-installers', action)>
            <ebay-arrow-right-24-icon/>
            <!-- Debug :- SVG not loading through the ebay UI-->
            <div hidden aria-hidden="true">
                <svg viewbox="0 0 20 18" id="icon-arrow-right-24">
                    <path d="m21.71 11.29-8-8a1.004 1.004 0 0 0-1.42 1.42l6.3 6.29H3a1 1 0 0 0 0 2h15.59l-6.3 6.29a1.001 1.001 0 0 0 0 1.42 1.001 1.001 0 0 0 1.42 0l8-8a1.001 1.001 0 0 0 0-1.42Z"></path>
                </svg>
            </div>
        </ebay-icon-button>
    </if>
</div>
