$ const { get } = require("@ebay/retriever");

class {
    onCreate(input) {
        this.state = {
            isBusy: false,
            loading: false
        };
    }

    onInput(input) {
        this.state.loading = input.showLoading || false;
    }

    handleClick(action) {
        this.state.isBusy = true;
        this.emit('click', action);
    }
}

$ {
    const {
        model: {
            VAS_CTA: cta,
            VAS_INTRO: introModel,
            VAS_STATUS_MESSAGE: defaultError,
            VAS_HEADER_MODULE: {
                closeIcon
            } = {},
        } =  {},
        global: {
            isMobile
        } = {},
        showError
    } = input;
    const {
        image: {
            URL: imageUrl, 
            title
        } = {},
        secondaryLabels
    } = introModel || {};
};

<header 
    class-name='introduction-header'
    model=closeIcon 
    on-close('emit', 'close')>
</header>

<if(showError)>
    <error-screen model=defaultError />
</if>
<else>
    <div class="parts-introduction">
        <if(state.loading)>
            <screen-loading top={count:isMobile ? 0 : 1, type:'textbox'} left={count:isMobile? 1 : 6, type: isMobile? 'image' : 'composite'} right={count:isMobile? 3 : 1, type: isMobile ? 'composite' : 'image'} footer={count:isMobile? 0 : 1, type:'textbox'}/>
        </if>
        <else>
            <introduction>
                <@topContent>
                    <div class="introduction__top-content">
                        <img src=imageUrl alt=title />
                    </div>
                </@topContent>
                <@bottomContent>
                    <div class="introduction__bottom-content">
                        <card-tile model=introModel />
                    </div>
                </@bottomContent>
                <@footer>
                    <div class="introduction__footer sticky_footer">
                        <if(secondaryLabels && secondaryLabels.length > 0)>
                            <div class="introduction__footer__sec-labels">
                                <for|secLabel| of=(secondaryLabels)>
                                    <div on-click('emit', 'sec-label-clicked', secLabel)>
                                        <ux-textualdisplay model=secLabel />
                                    </div>
                                </for>
                            </div>
                        </if>
                        <ctas
                            fluid=isMobile
                            model=cta
                            isBusy=state.isBusy
                            processing-action-name=get(cta, 'sections.0.dataItems.0.action.name')
                            on-click('handleClick') />
                    </div>
                </@footer>
            </introduction>
        </else>
    </div>
</else>