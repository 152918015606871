$ const { get } = require('@ebay/retriever');
$ const dataSets = get(input, 'model.sections.0.dataItems', []);
$ const processingActionName = get(input, 'processingActionName', '');
<div class="ctas">

     <!-- SVG not loading through the ebay UI bcoz of shadow dom implementation -->
     <div hidden aria-hidden="true">
        <svg>
            <symbol viewbox="0 0 24 24" fill="none" id="icon-spinner-24">
                <path d="M22.5 12A10.5 10.5 0 1 1 9.514 1.798" stroke="var(--color-spinner-icon-background, #3665F3)" stroke-width=3 stroke-linecap="round" stroke-linejoin="round"></path>
                <path d="M14.606 1.829a10.5 10.5 0 0 1 4.056 2.055 10.499 10.499 0 0 1 2.806 3.577" stroke-width=3 stroke-linecap="round" stroke-linejoin="round" stroke="var(--color-spinner-icon-foreground, #E5E5E5)"></path>
            </symbol>
        </svg>
    </div>
        
    <for|button| of=dataSets>
        $ const action = button.action || {};
        $ const priority = button.type && button.type.toLowerCase();
        $ const currentActionName = get(action, 'name', '');
        <if(button.action)>
            $ const disable = input.disablePrimary && priority === 'primary';
            $ const href = ((!disable && action.type === 'NAV') ? action.URL : false);
            $ const busy = input.isBusy && (currentActionName !== '' && currentActionName === processingActionName);
            $ const buttonId = get(button, "action.name", "") ? `${get(button, "action.name", "").toLowerCase()}_id` : '';
            <if(buttonId)>
                <ebay-button
                    id=buttonId
                    href=(href && href.replace('<addonId>', input.addonId))
                    on-click('emit', 'click', action)
                    fluid=input.fluid
                    aria-label=action.accessibilityText
                    disabled=((input.disablePrimary && priority === 'primary') || busy)
                    bodyState=(busy && 'loading')
                    priority=priority>
                    ${button.text}
                </ebay-button>
            </if>
            <else>    
                <ebay-button
                    href=(href && href.replace('<addonId>', input.addonId))
                    on-click('emit', 'click', action)
                    fluid=input.fluid
                    aria-label=action.accessibilityText
                    disabled=((input.disablePrimary && priority === 'primary') || busy)
                    bodyState=(busy && 'loading')
                    priority=priority>
                    ${button.text}
                </ebay-button>
            </else>
        </if>
        <else>
            <div class='ctas__ok'>
                <ux-textspans model=[button] />
            </div>
        </else>
    </for>
</div>
