class {
    onCreate() {
        this.state = {
            hidePhoneNumber: true
        };
    }

    showPhoneNumber() {
        this.state.hidePhoneNumber = !this.state.hidePhoneNumber;
    }
}
$ const { get } = require('@ebay/retriever');

$ const serviceOperationDetails = input.model || [];

$ const place = get(serviceOperationDetails, '0.details');
$ const operation = get(serviceOperationDetails, '1.details');
$ const contact = get(serviceOperationDetails, '2.details');

$ const iconMapping = {
    'PLACE': 'icon-location-16',
    'CLOCK': 'icon-clock-16',
    'PHONE': 'icon-phone-16'
};

<for|operation| of=serviceOperationDetails>
    <if(operation)>
        $ {
            const details = operation.details;
            const title = operation.title;
            const expandControls = operation.expandControls;
            const icon = operation.icon || {};
        };

        <div class="parts-scheduling__service-operation">
            $ const iconName = icon.name  || '';

            // to make it dynamic used the svg syntax, instaed of using the ebay-icon
            <svg class=['icon', iconMapping[iconName] ] focusable="false" height="16" width="16" role="img" aria-label=iconName>
                <use href=`#${iconMapping[iconName]}`></use>
            </svg>
            <if(expandControls)>
                $ const hidePhoneNumber = state.hidePhoneNumber;
                <a href="javascript:void(0);" hidden=!hidePhoneNumber class=`parts-scheduling__${iconName.toLowerCase()}` on-click('showPhoneNumber')>
                    <ux-textualdisplay model=expandControls.viewMore />
                </a>
                <div hidden=hidePhoneNumber class=`parts-scheduling__${iconName.toLowerCase()}`>
                    <if(title)>
                        <ux-textualdisplay model=title />
                    </if>                
                    <if(details)>
                        <for|serviceDetail| of=details>
                            <ux-textualdisplay model=serviceDetail />
                        </for>
                    </if>
                </div>
            </if>
            <else>
                <p class=`parts-scheduling__${iconName.toLowerCase()}`>
                    <if(title)>
                        <ux-textualdisplay model=title />
                    </if>                
                    <if(details)>
                        <for|serviceDetail| of=details>
                            <ux-textualdisplay model=serviceDetail />
                        </for>
                    </if>
                </p>
            </else>
        </div>
    </if>
</for>        