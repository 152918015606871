const { get } = require('@ebay/retriever');

module.exports = class {
    onCreate(input) {
        this.shadowRootEl =  document.getElementById((input.isSpoke ? 'vas-spoke-target-d' : 'vas-interstitial-target-d'));
        this.state = {
            hideSort: true,
            value: ''
        };
    }

    handleClick() {
        this.state.hideSort = false;
    }

    /* istanbul ignore next */
    onMount() {
        if(this.shadowRootEl) {
            this.shadowRootEl.shadowRoot.addEventListener('click', (event) => {
                const dataset = get(event, 'target.dataset.sortClick');
                const isSort = (dataset &&  dataset === 'true');
                const sortState = this.state.hideSort;
                if(!isSort && !sortState) {
                    this.state.hideSort = true;
                }
            });
        }
    }

    handleValueEvent({ selected }) {
        const selectedValue = selected[0];

        this.state.value = selectedValue;

        if(selectedValue) {
            this.state.hideSort = true;
            this.emit('process-sort', {sortBy: selectedValue})
        }
    }
}
