$ const { get } = require('@ebay/retriever');

$ {
    const {
        country = 'us',
        language = 'en',
        selectedInstaller = {},
        model = [], // vasForm,
        variant,
        showInfo
    } = input;

    const css = name => `map__${name}`;
    const at = item => item instanceof Array ? item.map(at).join('. ') : ((item && item.accessibilityText) || '');
    const round5 = (rating) => {
    if(rating) {
        const ratingHypenValue = `${rating}`.split('.');

        return `${ratingHypenValue[0]}-${ratingHypenValue[1] > 2 ? 5 : 0}`;
    }
}
}
<div class=[css('root'), variant]>
    <div
        key="google-map"
        class=css('google-map')
        no-update
    >
    </div>
    <div
        key="installer-list"
        class=css('installer-list')
    >
        <for|installer, index| of=model>
            $ {
                const num = (index + 1).toLocaleString(language);
                const {
                    paramValue: id,
                    vasValues: {
                        distance,
                        latitude,
                        longitude,
                        name,
                        postalCode,
                        price
                    } = {},
                    accessoryLabels,
                    secondaryLabels,
                    label,
                    selected,
                    starRating: {
                        averageRating
                    } = {}
                } = installer;
                // todo: create accessible marker label
                const accessibilityText = at([label, accessoryLabels]);
                const select = selected || (selectedInstaller && selectedInstaller.paramValue === id);
            }
            <if(showInfo === 'info-label')>
                <button key=id class=[css('marker'), select ? css('marker--selected') : ''] on-click('handleMapInfoView', installer, index)>
                    <div
                        class=[select ? css('button-info-view-select') : css('button-info-view')]
                        aria-label=name
                        id:scoped=id>
                            <for|value, index| of=(secondaryLabels || [])>
                                <div class=css(`secondary-label-${index}`) title=value.accessibilityText>
                                    <ux-textualdisplay model=value />
                                </div>
                            </for>
                            <div class=css('star-rating')>
                                $ const ratingValue = (averageRating && averageRating.value) || 0;
                                <ebay-star-rating value=round5(ratingValue) a11yStarText=ratingValue />
                                <if(averageRating)>
                                    <span class=css('star-rating-average')>
                                        <ux-textualdisplay model=averageRating />
                                    </span>
                                </if>
                            </div>
                    </div>
                </button>
            </if>
            <else-if(showInfo === 'button')>
                <div key=id class=[css('marker'), select ? css('marker--selected') : '']>
                    <button
                        class=css('button-info-view-select')
                        aria-label=name
                        id:scoped=id>
                            <for|value, index| of=(secondaryLabels || [])>
                                <div class=css(`secondary-label-${index}`) title=value.accessibilityText>
                                    <ux-textualdisplay model=value />
                                </div>
                            </for>
                            <div class=css('star-rating')>
                                $ const ratingValue = (averageRating && averageRating.value) || 0;
                                <ebay-star-rating value=round5(ratingValue) a11yStarText=ratingValue />
                                <if(averageRating)>
                                    <span class=css('star-rating-average')>
                                        <ux-textualdisplay model=averageRating />
                                    </span>
                                </if>
                            </div>
                    </button>
                </div>                                           
            </else-if>
            <else>
                <div key=id class=[css('marker'), select ? css('marker--selected') : '']>
                    <input
                        class=css('input')
                        id:scoped=id
                        on-change('handleInstallerClick', installer)
                        type="radio"
                        name:scoped="map"
                        checked=select
                    />
                    <label
                        class=css('container')
                        for:scoped=id
                        title=accessibilityText
                        aria-label=accessibilityText
                    >
                        <div class=css('num')>${num}</div>
                        <div class=css('label')>
                            <ux-textualdisplay model=label />
                        </div>
                            <for|value, index| of=(accessoryLabels || [])>
                                <div class=css(`accessory-label-${index}`) title=value.accessibilityText>
                                    <ux-textualdisplay model=value />
                                </div>
                            </for>
                    </label>
                </div>                   
            </else>
            <if(showInfo)>
                <button key=`info-card-${id}` class=css('installer-card') onclick('emit', 'info-card-click', installer)>
                    <installer-card
                        key=`installer-card-${id}`
                        model=installer
                        variant="no-radio" />
                </button>
            </if>       
        </for>
    </div>
</div>
