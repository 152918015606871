class {
    onInput(input) {
        this.state = {
            pressed: [],
        }
    }
    
    handleButton({ pressed }) {
        this.state.pressed = pressed;
        const model = this.input.model;
        const selectedEntry = model.entries && model.entries[pressed[0]];
        if (selectedEntry.paramValue) {
            this.emit('time-slot-click', { selectedSlot: selectedEntry.paramValue, vasSelection: selectedEntry.additionalParamKeyValues });
        }
    }
}

$ const {
    model: {
        heading: timeSlotHeading,
        entries: timeSlots
    } = {},
    enableSlots,
    selectedSlot
} = input;

<div class="parts-scheduling__time-slot">
    <h4>
        <ux-textualdisplay model=timeSlotHeading />
    </h4>
    <ebay-toggle-button-group variant="radio" on-change("handleButton")>
        <for|timeslot, i| of=timeSlots>
            $ {
                const defaultSelect = selectedSlot && selectedSlot === timeslot.paramValue;
                if (!state.pressed.length && defaultSelect) {
                    state.pressed = [i];
                }
            };

            <@button disabled=(!enableSlots.includes(timeslot.paramValue)) pressed=state.pressed.includes(i)>
                <ux-textualdisplay model=timeslot.label />
            </@button>                
        </for>
    </ebay-toggle-button-group>          
</div>  