
$ const { get } = require("@ebay/retriever");

$ {
    const providerModule = get(input, 'model', {});
    const providerImageUrl = get(providerModule, 'image.URL', '');
    const providerImageTitle = get(providerModule, 'image.title', '');
    const providerTexts = get(providerModule, 'text', []);
}

<if(!providerModule.__isEmpty)>
    <div class="vas-provider">
        <if(providerImageUrl)>
            <div class="vas-provider__image">
                <img src=providerImageUrl alt=providerImageTitle/>
            </div>
        </if>
        <if(Array.isArray(providerTexts) && providerTexts.length > 0)>
            <for|providerText| of=(providerTexts || [])>
                <div>
                    <ux-textualdisplay model=providerText/>
                </div>
            </for>
        </if>
    </div>
</if>
