$ const { get } = require('@ebay/retriever');

$ const {
    model: {
        placeHolder,
        validations,
        paramKey,
        label,
        accessibilityText,
    } = {},
    fluid
} = input;

$ const {
    error,
    value
} = state;

<div class="postal-code">
    <ebay-textbox
        key="postal-code"
        fluid
        value=value
        name=paramKey
        autocomplete="off"
        button-aria-label="Clear zip code"
        aria-label=accessibilityText
        invalid=error
        floating-label=get(label, 'textSpans.0.text')
        placeholder=get(placeHolder, 'textSpans.0.text')
        on-input-change('handleValueEvent', 'immediate-value-change')
        on-keydown('handleKeyEvent')
        on-change('handleValueEvent', 'value-change')
        on-button-click('clearInput')>
            <if(!error)>
                <@postfix-icon>
                    <ebay-close-16-icon/>
                </@postfix-icon>
            </if>
        </ebay-textbox>
        <p hidden=(!error) class="postal-code__error-message">
            <ux-textualdisplay model=get(validations, '0.message.title', {}) />
        </p>
</div>
