const { get } = require('@ebay/retriever');

module.exports = class {
    onCreate(input) {
        const postalCode = input.value;
        this.pattern = get(input, 'model.validations.0.textPatterns.0');
        this.state = {
            value: postalCode,
            error: !postalCode
        };
    }

    handleValueEvent(eventName, { value }) {
        const upperCaseValue = value.toUpperCase();
        const valid = value && this.validateRegex(upperCaseValue);
        this.state.value = value;
        this.state.error = !valid;
        if(valid) {
            const selectedValue = {
                'name': eventName
            };
            selectedValue[get(this.input, 'model.paramKey')] = value;
            this.emit('value-change', selectedValue);
        }

        this.emit('disable-action', { invalidPostalCode: this.state.error });
    }

    clearInput() {
        this.state.error = true;
        this.state.value = '';
        this.getComponent('postal-code').focus();
        this.emit('disable-action', { invalidPostalCode: this.state.error });
    }

    validateRegex(value) {
        const regX = new RegExp(this.pattern);
        return regX.test(value)
    }

    handleKeyEvent({ originalEvent = {}, value }) {
        if (originalEvent.key === 'Enter') {
            this.handleValueEvent('keyDown', { originalEvent, value });
        }
    }
}
