const { get } = require('@ebay/retriever');

module.exports = class {
    onCreate(input) {
        const personalData = this.getPersonalData(input.model);
        this.personalDataFields = Object.keys(personalData.personalDataValue) || [];
        this.state = {
            validationResult: personalData.validationResult,
            ...personalData.validationResult,
            ...personalData.personalDataValue,
            loading: false
        };
    }

    onMount() {
        this.broadcastLicenseChange();
    }

    broadcastLicenseChange() {
        const selectedParam = this.addtionalParamKey || {};

        if (this.personalDataFields.length) {
            this.personalDataFields.forEach((field) => {
                selectedParam[field] = this.state[field];
            });
        }

        this.emit('personal-data-change', { selectedParam });
    }

    getValidationResult(paramValue, validations) {
        for (const validation of validations) {
            const textPatterns = get(validation, 'textPatterns', []);
            for (const pattern of textPatterns) {
                const result = new RegExp(pattern).test(paramValue);
                if (!result) {
                    return false;
                }
            }
        }
        return true;
    }

    handleDropdownInputChange(paramKey, validation, evt) {
        const inputValue = evt.selected[0]
        const currValidationResult = this.getValidationResult(inputValue, validation);
        const validationResult = this.state.validationResult;
        validationResult[`${paramKey}_result`] = currValidationResult;
        validationResult[`${paramKey}_showError`] = true;
        this.setState({
            validationResult,
            ...validationResult,
            [paramKey]: inputValue
        })
        this.broadcastLicenseChange();

    }

    handleInputChange(paramKey, validation, evt) {
        const inputValue = evt.value;
        const currValidationResult = this.getValidationResult(inputValue, validation);
        const validationResult = this.state.validationResult;
        validationResult[`${paramKey}_result`] = currValidationResult;
        validationResult[`${paramKey}_showError`] = true;
        this.setState({
            validationResult,
            ...validationResult,
            [paramKey]: inputValue
        })
        this.broadcastLicenseChange();
    }

    getPersonalData(vasPersonalData) {
        const dataItems = get(vasPersonalData, 'dataItems', []);
        const validationResult = {};
        const personalDataValue = {};

        dataItems.forEach(dataItem => {
            if (dataItem.uxComponentHint === 'DROP_DOWN') {
                this.populateDropdownEntry(dataItem, validationResult, personalDataValue);
            } else if (dataItem.uxComponentHint === 'TextualEntry') {
                this.populateTextboxEntry(dataItem, validationResult, personalDataValue);
            }
        });

        return {
            validationResult,
            personalDataValue,
        };
    }

    populateDropdownEntry(dataItem, validationResult, personalDataValue) {
        const validations = get(dataItem, 'validations', []);
        const defaultParamValue = "";

        // set results and error for first entry
        if (dataItem.entries) {
            const entry = dataItem.entries[0];
            personalDataValue[entry.paramKey] = defaultParamValue;
            validationResult[`${entry.paramKey}_result`] = this.getValidationResult(defaultParamValue, validations);
            validationResult[`${entry.paramKey}_showError`] = false;
        }

    }

    populateTextboxEntry(dataItem, validationResult, personalDataValue) {
        const validations = get(dataItem, 'validations', []);
        const defaultParamValue = "";

        dataItem.entries.forEach(entry => {
            personalDataValue[entry.paramKey] = defaultParamValue;
            validationResult[`${entry.paramKey}_result`] = this.getValidationResult(defaultParamValue, validations);
            validationResult[`${entry.paramKey}_showError`] = false;
        });

        return {validationResult, personalDataValue};
    }

}